import { Button } from 'app/App.components/Button/Button.view'
import { WalletState } from 'reducers/wallet'

import { TransactionModal } from '../../../TransactionModal/TransactionModal.controller'
import {
  CardActionBalance,
  CardActionBalanceContainer,
  CardActionBalanceTitle,
  CardActionBlueBar,
  CardActionBold,
  CardActionButtonContainer,
  CardActionContainer,
  CardActionDisabledInput,
  CardActionDisabledInputContainer,
  CardActionDisabledInputRight,
  CardActionDisabledInputSkewLeft,
  CardActionDisabledInputSkewRight,
  CardActionFirstLine,
  CardActionInput,
  CardActionInputContainer,
  CardActionInputRight,
  CardActionInputSkewLeft,
  CardActionInputSkewRight,
  CardActionLine,
  CardActionLineLeft,
  CardActionLineLeftGrid,
  CardActionLineLeftSubtext,
  CardActionLineRight,
  CardActionSeparator,
  CardActionStyled,
  CardActionSubTitle,
} from '../../CardAction.style'
import { BidYourBid } from './Bid.style'

interface CardActionViewProps {
  version: number
  owner: string
  collection: string
  wallet: WalletState
  updateModal: (title: string, children: JSX.Element) => void
  item: any
}

export const BidView = ({ owner, collection, wallet, updateModal, item }: CardActionViewProps) => {
  return (
    <CardActionStyled>
      <CardActionContainer>
        <CardActionFirstLine>
          You place a bid for <CardActionBold>{owner}</CardActionBold> by <CardActionBold>{collection}</CardActionBold>
        </CardActionFirstLine>
        <CardActionSubTitle>Reserve price</CardActionSubTitle>
        <CardActionDisabledInputContainer disabled={true}>
          <CardActionDisabledInput value={2000} disabled />
          <CardActionDisabledInputSkewLeft />
          <CardActionDisabledInputRight>MYTV</CardActionDisabledInputRight>
          <CardActionDisabledInputSkewRight />
        </CardActionDisabledInputContainer>
        <CardActionSubTitle>Latest bid</CardActionSubTitle>
        <CardActionDisabledInputContainer disabled={true}>
          <CardActionDisabledInput value={2000} disabled />
          <CardActionDisabledInputSkewLeft />
          <CardActionDisabledInputRight>MYTV</CardActionDisabledInputRight>
          <CardActionDisabledInputSkewRight />
        </CardActionDisabledInputContainer>
        <BidYourBid>Your bid</BidYourBid>
        <CardActionInputContainer>
          <CardActionInput placeholder="Enter amount" />
          <CardActionInputSkewLeft />
          <CardActionInputRight>MYTV</CardActionInputRight>
          <CardActionInputSkewRight />
        </CardActionInputContainer>
        <CardActionBalanceContainer>
          <CardActionBalanceTitle>Your balance</CardActionBalanceTitle>
          <CardActionBalance>{wallet.currencyAmount} MYTV</CardActionBalance>
        </CardActionBalanceContainer>
        <CardActionBlueBar src="/images/blue-bar.svg" alt="" />
        <CardActionLine>
          <CardActionLineLeft>Expiration time</CardActionLineLeft>
          <CardActionLineRight>7 days</CardActionLineRight>
        </CardActionLine>
        <CardActionSeparator />
        <CardActionLine>
          <CardActionLineLeft>
            <CardActionLineLeftGrid>
              <div>Service Fees</div>
              <CardActionLineLeftSubtext>2%</CardActionLineLeftSubtext>
            </CardActionLineLeftGrid>
          </CardActionLineLeft>
          <CardActionLineRight>0 MYTV</CardActionLineRight>
        </CardActionLine>
        <CardActionLine>
          <CardActionLineLeft>
            <CardActionLineLeftGrid>
              <div>Royalties</div>
              <CardActionLineLeftSubtext>20%</CardActionLineLeftSubtext>
            </CardActionLineLeftGrid>
          </CardActionLineLeft>
          <CardActionLineRight>0 MYTV</CardActionLineRight>
        </CardActionLine>
        <CardActionButtonContainer>
          <Button appearance="primary" clickCallback={() => updateModal('Transaction submitted', <TransactionModal />)}>
            PLACE A BID
          </Button>
        </CardActionButtonContainer>
      </CardActionContainer>
    </CardActionStyled>
  )
}
