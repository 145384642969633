import BaseService from 'Webservices/BaseService'

type IParamsGet = {} | null

type IQueryString = {
  userAddress: string
  tokenAddress: string
}

type IAllowanceResponse = {
  allowed: boolean
}


export default class Allowance extends BaseService {
  protected static readonly webServiceUrl = new URL(`${process.env.REACT_APP_API_BASEPATH}/erc721/balances`)
  public static async get(params: IParamsGet, queryString: IQueryString): Promise<IAllowanceResponse> {
    Object.entries(queryString).forEach(([key, value]) => Allowance.webServiceUrl.searchParams.set(key, value))
    return (await this.httpGet(Allowance.webServiceUrl)).json()
  }
}
