// prettier-ignore
import * as React from 'react'

import { SalesHistoryView } from './SalesHistory.view'
import { State } from '../../reducers/index'
import { useSelector } from 'react-redux'

export const SalesHistory = () => {
  const version = useSelector((state: State) => state.version.number)

  return <SalesHistoryView version={version} />
}
