// prettier-ignore
import * as React from 'react'

import { ActivitiesView } from './Activities.view'
import { State } from '../../reducers/index'
import { useDispatch, useSelector } from 'react-redux'
import { connectWallet } from 'actions/Wallet.actions'
import { Page } from 'styles'
export const Activities = () => {
  const dispatch = useDispatch()
  const version = useSelector((state: State) => state.version.number)
  const wallet = useSelector((state: State) => state.wallet)

  const connectWalletCb = () => {
    dispatch(connectWallet())
  }

  return (
    <>
      {version !== 0 && <ActivitiesView version={version} wallet={wallet} connectWallet={connectWalletCb} />}
      {version === 0 && <Page></Page>}
    </>
  )
}
