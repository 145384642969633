/* eslint-disable react-hooks/exhaustive-deps */
import { NftEntity } from 'Entities'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { State } from 'reducers'
import * as Webservices from 'Webservices'
import { MarketplaceView } from './Marketplace.view'

interface Filter {
  type: string
  label: string
  query: string
}

export const Marketplace = () => {
  const version = useSelector((state: State) => state.version.number)

  const [tokens, setTokens] = useState<NftEntity[]>([])
  const [filters, setFilters] = useState([] as Filter[])
  const [loading, setLoading] = useState(true)

  async function loadTokens() {
    let query = {}

    for (let i = 0; i < filters.length; i++) {
      if (filters[i].type === 'buy_now') {
        query = {
          ...query,
          tokenAddress: '0xE6017F1495d442d00704dA8Ed553Fad5765AfcF4',
        }
      }

      if (filters[i].type === 'auction') {
        query = {
          ...query,
          tokenAddress: '0xA2c680D6Ea654Cf4CA14c31549D9e9358559b9A5',
        }
      }
    }
    let data = await Webservices.Erc721.Tokens.get(null, { q: query })
    setTokens(data)
    setLoading(false)
  }

  function addOneFilter(filter: Filter) {
    if (filters.length >= 1) {
      let found = false
      for (let i = 0; i < filters.length; i++) {
        if (filters[i].query === filter.query && filters[i].label === filter.label) {
          found = true
        }
      }

      if (!found) {
        let cpyFilters: Filter[] = Array.from(filters)
        cpyFilters.push({
          type: filter.type,
          query: filter.query,
          label: filter.label,
        } as Filter)
        setFilters(cpyFilters)
      }
    } else {
      setFilters([
        {
          type: filter.type,
          query: filter.query,
          label: filter.label,
        },
      ])
    }
  }

  function addFilters(filtersToAdd: Filter[]) {
    // If there is no filters active, only setting those we want to add
    if (filters.length === 0) {
      setFilters(filtersToAdd)
      return
    }

    // If there is already some filters, copy the array, modifying it and then setting it
    let cpyFilters: Filter[] = Array.from(filters)

    let newFiltersToAdd = filtersToAdd.filter((item: any, key: number) => {
      for (let j = 0; j < cpyFilters.length; j++) {
        if (item.type === cpyFilters[j].type) {
          return false
        }
      }
      return true
    })

    setFilters(cpyFilters.concat(newFiltersToAdd))
  }

  function removeAllFilters() {
    setFilters([])
  }

  function removeOneFilter(type: string) {
    let cpyFilters: Filter[] = Array.from(filters)
    cpyFilters = cpyFilters.filter((item) => {
      if (item.type === type) {
        return false
      } else {
        return true
      }
    })
    setFilters(cpyFilters)
  }

  useEffect(() => {
    setLoading(true)
    loadTokens()
  }, [filters])

  return (
    <MarketplaceView
      version={version}
      tokens={tokens}
      filters={filters}
      removeOneFilter={removeOneFilter}
      addOneFilter={addOneFilter}
      removeAllFilters={removeAllFilters}
      addFilters={addFilters}
      loading={loading}
    />
  )
}
