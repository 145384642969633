import { Box, Image } from 'styles'

import { Content, SubInfoStyled, Title } from './SubInfo.style'

interface SubInfoProps {
  title: String
  value: String
}

export const SubInfo = ({ title, value }: SubInfoProps) => {
  return (
    <SubInfoStyled>
      <Box position='relative' height={60} width={50}>
        <Image top={-6.6} left={5} position='absolute' src='/images/collection/neon-arrow.svg' />
      </Box>
      <Box display='flex' flexDirection='column' alignItems='flex-start'>
        <Title>{title}</Title>
        <Content>{value}</Content>
      </Box>
    </SubInfoStyled >
  )
}
