import styled, { createGlobalStyle } from 'styled-components/macro'
import { Breakpoints } from 'styles/breakpoints'

export const DisableBody = createGlobalStyle`
  body{
    overflow: hidden;
  }
`
export const ModalStyled = styled.div`
  display: none;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  overflow: auto;
  position: fixed;

  top: 0px;

  z-index: 11;
  &.showing {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const ModalBody = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 50px;
  padding-bottom: 50px;
  @media (max-width: ${Breakpoints.lg}) {
    display: block;
    width: calc(100% - 60px);
  }

  @media (max-width: ${Breakpoints.xs}) {
    display: block;
    width: calc(100% - 30px);
  }
`

export const ModalImgContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  padding: 0 57px;
  background: black;
  @media (max-width: ${Breakpoints.lg}) {
    display: none;
  }
  max-width: 500px;
`
export const ModalImg = styled.img`
  width: 100%;
  padding: 57px 0;
`

export const ModalContainer = styled.div<{ hasImage: boolean }>`
  width: 500px;
  position: relative;
  padding: ${(props) => (props.hasImage ? '52px 30px 40px 30px' : '24px 30px 40px 30px')};
  background: linear-gradient(-135deg, transparent 30px, #232327 30px);

  @media (max-width: ${Breakpoints.lg}) {
    padding: 24px 20px;
    width: 100%;
  }
`

export const ModalFirstLineContainer = styled.div`
  position: relative;
`

export const ModalTitle = styled.p`
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */

  color: #f8fafc;
  text-align: left;
  margin: 0;

  @media (max-width: ${Breakpoints.sm}) {
    font-size: 18px;
    line-height: 22px;
  }
`

export const ModalCross = styled.span`
  position: absolute;
  top: 30px;
  right: 40px;
  cursor: pointer;

  font-family: Fira Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */

  color: #f8fafc;
  z-index: 5;
`
