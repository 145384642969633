
import { TokenSupportEntity } from 'Entities';
import BaseService from './BaseService';

type IParamsGet = {

} | null;

type IQueryString = {
  q?: object
  page?: number
  limit?: number
  sort?: object
}


export default class TokensSupport extends BaseService{
  protected static readonly webServiceUrl = new URL(`${process.env.REACT_APP_API_BASEPATH}/erc721/tokens`)
  public static async get(params: IParamsGet, queryString: IQueryString) : Promise<TokenSupportEntity[]>{
    Object.entries(queryString).forEach(([key, value]) => TokensSupport.webServiceUrl.searchParams.set(key, JSON.stringify(value)))
    return (await this.httpGet(TokensSupport.webServiceUrl)).json()
  } 
}