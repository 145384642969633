import styled from 'styled-components/macro'
import { Image, Page } from 'styles'
import { Breakpoints } from 'styles/breakpoints'

export const CollectionStyled = styled(Page)`
  width: 100%;
  text-align: center;
  padding: 0;
`

export const VideoContainer = styled.div`
  position: relative;
  @media (max-width: ${Breakpoints.md}) {
    display: none;
  }
`
export const VideoBackground = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: 0;

  overflow: hidden;
`

export const LinearGradientBox = styled.div`
  position: absolute;
  background: linear-gradient(0deg, #101010 20.17%, rgba(16, 16, 16, 0) 100%);
  height: 36%;
  width: 100%;
  bottom: 100px;

  @media (max-width: ${Breakpoints.md}) {
    bottom: 0;
  }
`

export const WaitListContainer = styled.div`
  position: absolute;
  z-index:1;
  display: flex;
  flex-direction: column;

  font-family: Termina;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 40px;

  text-align: center;
  text-transform: uppercase;
  align-items: center;
  bottom: 210px;

  @media (max-width: ${Breakpoints.md}) {
    display: none;
  }

  @media (min-width: ${Breakpoints.xxxl}) {
    bottom: 270px;
  }
`

export const BigPurpleText = styled.div`
  font-family: Termina;
  font-style: normal;
  font-weight: 800;
  font-size: 34px;
  line-height: 41px;
  text-align: center;
  text-transform: uppercase;

  background: -webkit-linear-gradient(to right, #9542FF 0%, #3AD8ED 100%);
  background: -moz-linear-gradient(to right, #9542FF 0%, #3AD8ED 100%);
  background: linear-gradient(to right, #9542FF 0%, #3AD8ED 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

export const NewComerGuidContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: -70px;
  background: linear-gradient(102.77deg, #101010 0%, #19191B 45.7%, #232327 64.23%, #101010 79.13%);

  @media (max-width: ${Breakpoints.md}) {
    margin-top: 0;
    margin-right: 30px;
    margin-left: 30px;
    padding-bottom: 15px;
  }

  @media (min-width: ${Breakpoints.xxxl}) {
    justify-content: space-evenly;
  }

`

export const GuideTitle = styled.p`
  font-family: 'Termina';
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 34px;
  text-transform: uppercase;
  color: #FFFFFF;

  @media (max-width: ${Breakpoints.md}) {
    font-size: 20px;
  }

`

export const HowToBuyNftLink = styled.a`
  font-family: 'Circe';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  text-decoration-line: underline;
  text-transform: uppercase;

  color: #F8FAFC;

  @media (max-width: ${Breakpoints.md}) {
    font-size: 14px;
  }

  
`

export const SeriesContainer = styled.div<{ borderTop?: boolean }>`
  position: relative;
  overflow: hidden;

  border-top: ${(props) => (props.borderTop ? `1px solid #61616B;` : `none`)};

  @media (max-width: ${Breakpoints.md}) {
    display: none;
  }
`

export const SeriesContainerBackground = styled.div<{ background: string; }>`
  background: ${(props) => `${props.background}`};
  filter: blur(200px);
  transform: rotate(-180deg);

  position: absolute;
  width: 50%;
  height: 50%;
  top: 25%;
  left: 25%;
`

export const PremiumSeriesTitle = styled.p`
  position: relative;
  font-family: 'Termina';
  font-style: normal;
  font-weight: 800;
  font-size: 34px;
  line-height: 41px;

  text-align: center;
  text-transform: uppercase;

  background: linear-gradient(90.65deg, #BE9B4B 7.3%, #FBE999 27.18%, #FFFFFF 35.63%, #FFFFC0 46.07%, #D0B463 102.74%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-text-fill-color: transparent;

  text-shadow: 0px 0px 10px rgba(239, 203, 74, 0.71);

  margin-top: 40px;
  margin-bottom: 40px;

  z-index: 1;

  @media (max-width: ${Breakpoints.md}) {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 66px;

    font-size: 28px;
  }

`

export const FanSeriesTitle = styled(PremiumSeriesTitle)`
  background: linear-gradient(90.65deg, #A2A2A2 7.3%, #E7E7E7 27.18%, #FFFFFF 35.63%, #B8B8B8 46.07%, #B8B8B8 102.74%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 0px 10px rgba(187, 187, 187, 0.55);

  @media (max-width: ${Breakpoints.md}) {
    padding-top: 66px;
  }
`

export const YellowImage = styled(Image)`
  position: absolute;
  top: -30px;
  left: -300px;
  transform: rotate(-2deg);
  z-index: 1;

  @media (max-width: ${Breakpoints.md}) {
    top: -80px;
    left: -220px;
  }
`

export const RowInfoContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 1;
  margin-bottom: 72px;
  margin-left: 80px;
  margin-right: 80px;
`

export const GrayImage = styled(Image)`
  position: absolute;
  bottom: 50px;
  right: 50px;
  transform: rotate(-2deg);
  z-index: 1;

  @media (max-width: ${Breakpoints.md}) {
    bottom: 20px;
    right: -130px;
  }

`

export const Shadow = styled.div`
  position: relative;
  background: radial-gradient(56.4% 56.4% at 40.19% 44.4%, rgba(16, 16, 16, 0.7) 0%, rgba(16, 16, 16, 0) 68.23%);
`

export const VideoPreviewContainerMobile = styled.div`
  position: relative;
  @media (min-width: ${Breakpoints.md}) {
    display: none;
  }
`

export const WaitListContainerMobile = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;

  font-family: Termina;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 40px;

  text-align: center;
  text-transform: uppercase;
  align-items: center;

  margin-bottom: 88px;
  margin-top: 24px;

  @media (min-width: ${Breakpoints.md}) {
    display: none;
  }
`

export const SeriesContainerMobile = styled.div<{ borderTop?: boolean }>`
  position: relative;
  overflow: hidden;

  margin-bottom: 64px;

  border-top: ${(props) => (props.borderTop ? `1px solid #61616B;` : `none`)};

  @media (min-width: ${Breakpoints.md}) {
    display: none;
  }
`

export const TitleSeriesContainer = styled.div`
  height: 129px;
  position: relative;
  overflow: hidden;
`

export const Neon = styled(Image)`
  @media (max-width: ${Breakpoints.md}) {
    display: none;
  }
`