import { combineReducers } from 'redux'

import { loading, LoadingState } from './loading'
import { modal, ModalState } from './modal'
import { progressBar, ProgressBarState } from './progressBar'
import { toaster, ToasterState } from './toaster'
import { wallet, WalletState } from './wallet'
import { version, VersionState } from './version'

export const reducers = combineReducers({
  loading,
  progressBar,
  toaster,
  modal,
  wallet,
  version
})

export interface State {
  loading: LoadingState
  progressBar: ProgressBarState
  toaster: ToasterState
  modal: ModalState,
  wallet: WalletState
  version: VersionState
}
