import { EmailComponent } from 'app/App.components/EmailComponent/EmailComponent.view'
import ReactPlayer from 'react-player'
import { Box, MaxScreenWidth } from 'styles'
import {
  BigPurpleText,
  CollectionStyled,
  FanSeriesTitle,
  GrayImage,
  GuideTitle,
  HowToBuyNftLink,
  LinearGradientBox,
  Neon,
  NewComerGuidContainer,
  PremiumSeriesTitle,
  RowInfoContainer,
  SeriesContainer,
  SeriesContainerBackground,
  SeriesContainerMobile,
  Shadow,
  TitleSeriesContainer,
  VideoBackground,
  VideoContainer,
  VideoPreviewContainerMobile,
  WaitListContainer,
  WaitListContainerMobile,
  YellowImage,
} from './Collection.style'
import { InfoComponent } from './InfoComponent/InfoComponent.view'
import { PackComponent } from './PackComponent/PackComponent.view'

export const CollectionView = () => {
  return (
    <MaxScreenWidth maxWidth="2400px">
      <CollectionStyled>
        <VideoContainer>
          <VideoBackground>
            <ReactPlayer
              url="/videos/citroen dark mode boucle_V3.mp4"
              playing
              muted
              loop
              width="100%"
              height="100%"
              style={{ bottom: '100px', position: 'relative' }}
            />
            <LinearGradientBox></LinearGradientBox>
            <WaitListContainer>
              <Shadow>
                <BigPurpleText>"the first"</BigPurpleText>
                <Box marginBottom={16}>join the waitlist now!</Box>
              </Shadow>
              <EmailComponent skew={true} backgroundColorInput="#232327" buttonText="JOIN" emailListIds={[97]} />
            </WaitListContainer>
          </VideoBackground>
        </VideoContainer>
        <VideoPreviewContainerMobile>
          <ReactPlayer
            url="/videos/citroen dark mode boucle_V3.mp4"
            playing
            playsinline
            muted
            loop
            width="100%"
            height="100%"
            style={{ position: 'relative' }}
          />
          <LinearGradientBox></LinearGradientBox>
        </VideoPreviewContainerMobile>
        <WaitListContainerMobile>
          <BigPurpleText>"the first"</BigPurpleText>
          <Box marginBottom={16}>join the waitlist now!</Box>
          <EmailComponent skew={false} backgroundColorInput="#232327" buttonText="JOIN" emailListIds={[97]} />
        </WaitListContainerMobile>
        <MaxScreenWidth>
          <NewComerGuidContainer>
            <Neon style={{ transform: 'rotateY(180deg' }} src="/images/collection/piece_neon.svg" />
            <Box display="flex" flexDirection="column" alignItems="center">
              <GuideTitle>how to buy nft?</GuideTitle>
              <HowToBuyNftLink href="https://docs.mytvchain.io/">step by step guide for newcomers</HowToBuyNftLink>
            </Box>
            <Neon src="/images/collection/piece_neon.svg" />
          </NewComerGuidContainer>
        </MaxScreenWidth>
        <SeriesContainer>
          <SeriesContainerBackground background="rgba(239, 203, 74, 0.3);"></SeriesContainerBackground>
          <MaxScreenWidth>
            <YellowImage src="/images/yellow-paint.svg" />
            <PremiumSeriesTitle>premium series</PremiumSeriesTitle>
            <RowInfoContainer>
              <InfoComponent
                series="premium"
                mobileVersion={false}
                uniqueNfts="2004"
                myTvchain='"the first" premium series'
                staring="sébastien loeb"
                mintDate="soon"
                maxAmountPerUser="tba"
                numberSold="0"
              />
              <PackComponent videoUrl="/videos/Blister_Loeb_PREMIUM.mp4" />
            </RowInfoContainer>
          </MaxScreenWidth>
        </SeriesContainer>
        <SeriesContainer borderTop={true}>
          <MaxScreenWidth>
            <SeriesContainerBackground background="rgba(196, 196, 196, 0.4);"></SeriesContainerBackground>
            <FanSeriesTitle> fan series </FanSeriesTitle>
            <GrayImage src="/images/gray-paint.svg" />
            <RowInfoContainer>
              <InfoComponent
                series="fan"
                mobileVersion={false}
                uniqueNfts="2004"
                myTvchain='"the first" fan series'
                staring="sébastien loeb"
                mintDate="soon"
                maxAmountPerUser="tba"
                numberSold="0"
              />
              <PackComponent videoUrl="/videos/Blister_Loeb_FAN.mp4" />
            </RowInfoContainer>
          </MaxScreenWidth>
        </SeriesContainer>
        <SeriesContainerMobile>
          <TitleSeriesContainer>
            <YellowImage src="/images/yellow-paint.svg" />
            <PremiumSeriesTitle>premium series</PremiumSeriesTitle>
          </TitleSeriesContainer>
          <InfoComponent
            series="premium"
            mobileVersion={true}
            uniqueNfts="2004"
            myTvchain='"the first" premium series'
            staring="sébastien loeb"
            mintDate="soon"
            maxAmountPerUser="tba"
            numberSold="0"
          />
          <PackComponent videoUrl="/videos/Blister_Loeb_PREMIUM.mp4" />
          <TitleSeriesContainer>
            <GrayImage src="/images/gray-paint.svg" />
            <FanSeriesTitle>fan series</FanSeriesTitle>
          </TitleSeriesContainer>
          <InfoComponent
            series="fan"
            mobileVersion={true}
            uniqueNfts="2004"
            myTvchain='"the first" fan series'
            staring="sébastien loeb"
            mintDate="soon"
            maxAmountPerUser="tba"
            numberSold="0"
          />
          <PackComponent videoUrl="/videos/Blister_Loeb_FAN.mp4" />
        </SeriesContainerMobile>
      </CollectionStyled>
    </MaxScreenWidth>
  )
}
