import { useState } from 'react'
import { WalletState } from 'reducers/wallet'
import {
  TransactionModalStyled,
  TransactionModalContainer,
  TransactionModalLoader,
  TransactionModalLink,
} from './TransactionModal.style'

interface TransactionModalViewProps {
  version: number
  wallet: WalletState
}

export const TransactionModalView = ({ version, wallet }: TransactionModalViewProps) => {
  let [finished, setFinished] = useState(false)

  setTimeout(() => {
    setFinished(true)
  }, 3000)

  return (
    <TransactionModalStyled>
      <TransactionModalContainer>
        <TransactionModalLoader
          src={finished ? '/images/transaction-loader-finished.png' : '/images/transaction-loader.png'}
          alt=""
          finished={finished}
        />
        <TransactionModalLink href="#">View in blockexplorer</TransactionModalLink>
      </TransactionModalContainer>
    </TransactionModalStyled>
  )
}
