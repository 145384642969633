import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { State } from 'reducers'
import { Image } from 'styles'

import { Hamburger } from './Hamburger/Hamburger.view'
import { AddressImage, CollectionLink, ConnectButton, ConnectButtonText, ConnectedButtonsContainer, CustomNavbarButton, MenuBar, MenuLogo, MenuLogo2, MenuLogoTablet, MenuStyled, ProfileImage, SubMenuContainer, SubMenuElement, WalletAddress, WalletBalance } from './Menu.style'

type MenuViewProps = {
  connectWallet: () => void
  walletLogout: () => void
  version: number
}

export const MenuView = ({ connectWallet, walletLogout, version }: MenuViewProps) => {
  const wallet = useSelector((state: State) => state.wallet)

  const history = useHistory()
  const [showing, setShowing] = useState(false)

  const [subMenuProfile, setSubMenuProfile] = useState(false)

  function printPartialAddress(address: String) {
    let length = address.length
    return address.substring(0, 4) + '...' + address.substring(length - 3, length)
  }

  let connectedComponents
  let walletBalanceComponent
  let walletAddressComponent
  let timeout: number
  if (wallet.address !== '') {
    walletBalanceComponent = (
      <WalletBalance style={{ pointerEvents: 'none' }} to="">
        {wallet.currencyAmount}&nbsp;MYTV
      </WalletBalance>
    )

    walletAddressComponent = (
      <WalletAddress
        onClick={() => {
          navigator.clipboard.writeText(wallet.address)
        }}
      >
        {printPartialAddress(wallet.address)}
        <AddressImage alt="Address Logo" src="/images/address.svg" />
      </WalletAddress>
    )

    connectedComponents = (
      <ConnectedButtonsContainer>
        <CustomNavbarButton
          onMouseEnter={() => {
            setSubMenuProfile(true)
            window.clearTimeout(timeout)
          }}
          onMouseLeave={() => {
            timeout = window.setTimeout(() => {
              setSubMenuProfile(false)
            }, 500)
          }}
          onClick={() => {
            setSubMenuProfile(!subMenuProfile)
          }}
          key="2"
        >
          <ProfileImage src="/images/profile.svg" alt="profile" />
          <SubMenuContainer showing={subMenuProfile}>
            <SubMenuElement
              onClick={() => {
                history.push('/profile')
                setSubMenuProfile(false)
              }}
            >
              Profile
            </SubMenuElement>
            <Image alt="Line separator navbar" src="/images/line-separator.svg"></Image>
            <SubMenuElement
              onClick={() => {
                history.push('/sales-history')
                setSubMenuProfile(false)
              }}
            >
              Sales history
            </SubMenuElement>
            <Image alt="Line separator navbar" src="/images/line-separator.svg"></Image>
            <SubMenuElement
              style={version === 0 ? { pointerEvents: 'none' } : {}}
              onClick={() => {
                history.push('/settings')
                setSubMenuProfile(false)
              }}
            >
              Settings
            </SubMenuElement>
            <Image alt="Line separator navbar" src="/images/line-separator.svg"></Image>
            <SubMenuElement
              onClick={() => {
                setSubMenuProfile(false)
                walletLogout()
              }}
            >
              Logout
            </SubMenuElement>
          </SubMenuContainer>
        </CustomNavbarButton>
        <CustomNavbarButton
          key="1"
          onMouseEnter={() => {
            setSubMenuProfile(false)
            window.clearTimeout(timeout)
          }}
          onMouseLeave={() => {
            timeout = window.setTimeout(() => {
              setSubMenuProfile(false)
            }, 500)
          }}
        ></CustomNavbarButton>
      </ConnectedButtonsContainer>
    )
  } else {
    connectedComponents = (
      <ConnectButton
        onClick={() => {
          connectWallet()
        }}
      >
        <ConnectButtonText>Connect Wallet</ConnectButtonText>
      </ConnectButton>
    )
  }

  return (
    <MenuStyled>
      <Hamburger showing={showing} setShowing={() => setShowing(!showing)} />
      <MenuBar showing={showing} logged={wallet.address !== ''} menuLength={wallet.address === '' ? '7' : '8'}>
        <Link to="/" onClick={() => setShowing(false)}>
          <MenuLogo alt="logo" src="/logo.svg" />
          <MenuLogo2 alt="logo" src="images/logo-simple.svg" />
        </Link>
        <MenuLogoTablet to="/" onClick={() => setShowing(false)} connected={wallet.address !== '' ? 'true' : 'false'}>
          <Image alt="logo" src="/logo.svg" />
        </MenuLogoTablet>
        <div />
        <Link to="/pack" onClick={() => setShowing(false)}>
          PACK
        </Link>
        <Link to="/marketplace" onClick={() => setShowing(false)}>
          MARKETPLACE
        </Link>
        <Link to="/challenge" onClick={() => setShowing(false)}>
          CHALLENGE
        </Link>
        <Link to="/activities" onClick={() => setShowing(false)}>
          ACTIVITIES
        </Link>
        <a href="https://mytvchain.medium.com/" onClick={() => setShowing(false)} target="_blank" rel="noreferrer">
          BLOG
        </a>
        <Link style={version === 0 ? { pointerEvents: 'none' } : {}} to="/tutorials" onClick={() => setShowing(false)}>
          TUTORIALS
        </Link>
        <CollectionLink to="/collection/sloeb" onClick={() => setShowing(false)}>
          <div>S.LOEB</div>
        </CollectionLink>
        {walletBalanceComponent}
        {walletAddressComponent}
        {connectedComponents}
      </MenuBar>
    </MenuStyled>
  )
}
