import styled from 'styled-components/macro'
import { Breakpoints } from 'styles/breakpoints'

export const TimerStyled = styled.div`
  display: grid;
  grid-template-columns: fit-content(0px) 10px fit-content(0px) 10px fit-content(0px);
  justify-content: center;
`

export const TimerNumber = styled.div`
  font-family: Fira Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */

  color: #f8fafc;

  @media (max-width: ${Breakpoints.lg}) {
    font-family: Circe;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
  }
`
export const TimerText = styled.div`
  font-family: Fira Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */

  color: #8f8f8f;

  @media (max-width: ${Breakpoints.lg}) {
    display: none;
  }
`

export const TimerEnded = styled.div`
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  text-transform: uppercase;

  color: #4934c3;
`
