import { OrderEntity } from 'Entities'
import BaseService from './BaseService'

type IParamsGet = {} | null

type IParamsPost = {
  sellerAddress: string
  sellTokenAddress: string
  sellTokenId: number
  sellTokenAmount: number
  askTokenAddress: string
  askTokenId: number
  askTokenAmount: number
  startTime: number
  expirationTime: number
  salt: number
  sellAssetClass: number
  askAssetClass: number
  orderHash: string
  orderSignature: string
}

type IQueryString = {
  q?: object
  page?: number
  limit?: number
  sort?: object
}

type IValidateQueryString = {
  tokenId: string
  tokenAddress: string
}

export class OrderValidationResponse {
  target?: OrderEntity
  property!: string
  value?: any
  constraints?: {
    [type: string]: string
  }
  children?: OrderValidationResponse[]
  contexts?: {
    [type: string]: any
  }
}

export default class Orders extends BaseService {
  protected static readonly webServiceUrl = new URL(`${process.env.REACT_APP_API_BASEPATH}/orders`)

  public static async get(params: IParamsGet, queryString: IQueryString): Promise<OrderEntity[]> {
    Object.entries(queryString).forEach(([key, value]) => Orders.webServiceUrl.searchParams.set(key, JSON.stringify(value)))
    return (await this.httpGet(Orders.webServiceUrl)).json()
  }

  public static async post(params: IParamsPost): Promise<OrderEntity> {
    return (await this.httpPost(Orders.webServiceUrl, params)).json()
  }

  public static async validate(
    params: IParamsGet,
    queryString: IValidateQueryString,
  ): Promise<OrderValidationResponse> {
    const webServiceValidateUrl = new URL(Orders.webServiceUrl.toString().concat('/validate'))
    Object.entries(queryString).forEach(([key, value]) => webServiceValidateUrl.searchParams.set(key, value.toString()))
    return (await this.httpGet(webServiceValidateUrl)).json()
  }
}
