import { Button } from 'app/App.components/Button/Button.view'
import { useState } from 'react'
import { WalletState } from 'reducers/wallet'

import {
  CardActionBlueBar,
  CardActionInput,
  CardActionInputContainer,
  CardActionInputRight,
  CardActionInputSkewLeft,
  CardActionInputSkewRight,
  CardActionLine,
  CardActionStyled,
  CardActionSubTitle,
} from '../../CardAction.style'
import {
  CardActionSaleButtonContainer,
  CardActionSaleContainer,
  CardActionSaleFees,
  CardActionSaleLineLeft,
  CardActionSaleLineRight,
} from './Sale.style'

interface CardActionViewProps {
  version: number
  owner: string
  collection: string
  wallet: WalletState
  updateModal: (title: string, children: JSX.Element) => void
  item: any
  sellToken: (price: number) => void
}

export const SaleView = ({ owner, collection, wallet, updateModal, item, sellToken }: CardActionViewProps) => {
  const [price, setPrice] = useState(0)
  return (
    <CardActionStyled>
      <CardActionSaleContainer>
        <CardActionSubTitle>Price</CardActionSubTitle>
        <CardActionInputContainer>
          <CardActionInput
            type="number"
            step="0.001"
            placeholder="Enter amount"
            onChange={(e: any) => setPrice(e.target.value)}
          />
          <CardActionInputSkewLeft />
          <CardActionInputRight>MYTV</CardActionInputRight>
          <CardActionInputSkewRight />
        </CardActionInputContainer>
        <CardActionBlueBar src="/images/blue-bar.svg" alt="" />
        <CardActionSaleFees>Fees</CardActionSaleFees>
        <CardActionLine>
          <CardActionSaleLineLeft>Service fees</CardActionSaleLineLeft>
          <CardActionSaleLineRight>2.5%</CardActionSaleLineRight>
        </CardActionLine>
        <CardActionLine>
          <CardActionSaleLineLeft>Creator royalties</CardActionSaleLineLeft>
          <CardActionSaleLineRight>10%</CardActionSaleLineRight>
        </CardActionLine>
        <CardActionSaleButtonContainer>
          <Button appearance="primary" clickCallback={() => sellToken(price)} disabled={price <= 0}>
            SELL
          </Button>
        </CardActionSaleButtonContainer>
      </CardActionSaleContainer>
    </CardActionStyled>
  )
}
