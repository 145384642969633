import { NftEntity } from 'Entities'
import BaseService from 'Webservices/BaseService'

type IParamsGet = {} | null

type IQueryString = {
  q?: object
  page?: number
  limit?: number
  sort?: object
}


export default class Tokens extends BaseService {
  protected static readonly webServiceUrl = new URL(`${process.env.REACT_APP_API_BASEPATH}/erc721/tokens`)

  public static async get(params: IParamsGet, queryString: IQueryString): Promise<NftEntity[]> {
    Object.entries(queryString).forEach(([key, value]) => Tokens.webServiceUrl.searchParams.set(key, JSON.stringify(value)))
    return (await this.httpGet(Tokens.webServiceUrl)).json()
  }
}
