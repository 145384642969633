// prettier-ignore
import * as React from 'react'

import { TableLineView } from './TableLine.view'
import { activityType, activityState } from '../Activities.enum'

import { showModalImg } from 'app/App.components/Modal/Modal.actions'
import { useDispatch } from 'react-redux'

interface TableLineProps {
  id: number
  type: activityType
}

export const TableLine = ({ id, type }: TableLineProps) => {
  const dispatch = useDispatch()

  let img = '/images/marketplace/cards/loeb.png'
  let price = 1
  let date = new Date()
  let name = 'Sebastien Loeb'
  let tokenId = '123456'
  let contractAddress = '0x06hjz...'

  let state = type === activityType.AUCTIONS ? id % 3 : (id % 2) + 3

  let now = new Date()
  now.setDate(now.getDate() + 2)
  let deadline = now.toString()

  if (state === activityState.FINALIZE) {
    deadline = 'Fri Jan 28 2022 11:00:00 UTC'
  }

  const showModalImgCb = (title: string, children: JSX.Element, image: string) => {
    dispatch(showModalImg(title, children, image))
  }

  return (
    <TableLineView
      id={id}
      img={img}
      price={price}
      date={date}
      name={name}
      tokenId={tokenId}
      contractAddress={contractAddress}
      type={type}
      state={state}
      deadline={deadline}
      showModalImg={showModalImgCb}
    />
  )
}
