import { Button } from 'app/App.components/Button/Button.view'
import { CardAction } from 'app/App.components/CardAction/CardAction.controller'
import { ModalType } from 'app/App.components/CardAction/CardAction.enum'
import { Loader } from 'app/App.components/Loader/Loader.view'
import * as PropTypes from 'prop-types'
import * as React from 'react'
import { useState } from 'react'
import { useHistory } from 'react-router'

import { Image } from '../../../../styles/components'
import {
  CardDetails,
  CardDetailsButtonContainer,
  CardEvent,
  CardGrayed,
  CardImage,
  CardName,
  CardNumber,
  CardPriceMYTV,
  CardPriceTitle,
  CardPriceUSD,
  CardPriceUSDResponsive,
  MarketplaceCardStyled,
  PriceLine,
} from './MarketplaceCard.style'

type MarketplaceCardProps = {
  imageLink: string
  name: string
  event: string
  number: number
  price: number
  priceUsd: number
  width?: string
  forSale?: boolean
  uniqueId: number
  showModal: (title: string, children: JSX.Element, imageLink: string) => void
  grayed: boolean
  detailsOnHover: boolean
  cardItem: any
  loading: boolean
}

export const MarketplaceCardView = ({
  imageLink,
  name,
  event,
  number,
  price,
  priceUsd,
  width,
  forSale,
  uniqueId,
  showModal,
  grayed,
  detailsOnHover,
  cardItem,
  loading,
}: MarketplaceCardProps) => {
  const baseImgUrl = '/images/marketplace'
  const history = useHistory()
  const [hovering, setHovering] = useState(false)
  const [hoveringDetails, setHoveringDetails] = useState(false)

  if (loading) {
    return <Loader />
  }
  if (imageLink === undefined) {
    return <MarketplaceCardStyled></MarketplaceCardStyled>
  }
  return (
    <MarketplaceCardStyled
      pointer={!forSale}
      onMouseEnter={() => {
        if (forSale) {
          setHovering(true)
        }
        if (detailsOnHover) {
          setHoveringDetails(true)
        }
      }}
      onMouseLeave={() => {
        if (forSale) {
          setHovering(false)
        }
        if (detailsOnHover) {
          setHoveringDetails(false)
        }
      }}
      onClick={() => {
        if (!forSale) {
          history.push(`/card/${uniqueId}`)
        }
      }}
    >
      {grayed && <CardGrayed />}
      <CardImage
        alt=""
        src={imageLink}
        onClick={() => {
          history.push(`/card/${uniqueId}`)
        }}
      ></CardImage>
      <CardDetails hovering={hoveringDetails} detailsOnHover={detailsOnHover}>
        <CardName>
          {name}
          <CardNumber>&nbsp;#{number}</CardNumber>
        </CardName>
        <CardEvent>{event}</CardEvent>
        <Image alt="Card separator" src={`${baseImgUrl}/card_line.svg`}></Image>
        {!hovering && (
          <>
            <PriceLine>
              <CardPriceTitle>Price</CardPriceTitle>
              <CardPriceMYTV>{price} MYTV</CardPriceMYTV>
              <CardPriceUSDResponsive>~ {priceUsd} USD</CardPriceUSDResponsive>
            </PriceLine>
            <CardPriceUSD>~ {priceUsd} USD</CardPriceUSD>
          </>
        )}
        {hovering && (
          <CardDetailsButtonContainer>
            <Button
              appearance="primary"
              width="100%"
              clickCallback={() =>
                showModal(
                  'Item for sale',
                  <CardAction owner="Michaël Guigou" collection="FFHB" type={ModalType.SALE} item={cardItem} />,
                  imageLink,
                )
              }
            >
              OFFER FOR SALE
            </Button>
          </CardDetailsButtonContainer>
        )}
      </CardDetails>
    </MarketplaceCardStyled>
  )
}

MarketplaceCardView.propTypes = {
  imageLink: PropTypes.string,
  name: PropTypes.string,
  event: PropTypes.string,
  number: PropTypes.number,
  price: PropTypes.number,
  priceUsd: PropTypes.number,
  width: PropTypes.string,
  marginRight: PropTypes.string,
  marginLeft: PropTypes.string,
  marginBottom: PropTypes.string,
}

MarketplaceCardView.defaultProps = {
  imageLink: 'loeb.png',
  name: 'Loading',
  event: 'Loading',
  number: 0,
  price: 0,
  priceUsd: 0,
}
