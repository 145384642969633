import { Activities } from 'pages/Activities/Activities.controller'
import { CardExtended } from 'pages/CardExtended/CardExtended.controller'
import { Challenge } from 'pages/Challenge/Challenge.controller'
import { Collection } from 'pages/Collection/Collection.controller'
import { Landing } from 'pages/Landing/Landing.controller'
import { Marketplace } from 'pages/Marketplace/Marketplace.controller'
import { Pack } from 'pages/Pack/Pack.controller'
import { Profile } from 'pages/Profile/Profile.controller'
import { SalesHistory } from 'pages/SaleHistory/SalesHistory.controller'
import React from 'react'
//@ts-ignore
import { Provider } from 'react-redux'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import { Footer } from './App.components/Footer/Footer.controller'
import { Menu } from './App.components/Menu/Menu.controller'
import { Modal } from './App.components/Modal/Modal.controller'
import { ProgressBar } from './App.components/ProgressBar/ProgressBar.controller'
import { ScrollToTop } from './App.components/ScrollToTop/ScrollToTop.view'
import { Toaster } from './App.components/Toaster/Toaster.controller'
import { VersionDebugger } from './App.components/VersionDebugger/VersionDebugger.controller'
import { WalletHandler } from './App.components/WalletHandler/WalletHandler.controller'
import { configureStore } from './App.store'
import { AppContainer } from './App.style'

export const saveState = (state: any) => {
  try {
    const serializedState = JSON.stringify({
      wallet: {
        address: state.wallet.address,
        currencyAmount: state.wallet.currencyAmount,
      },
    })
    localStorage.setItem('state', serializedState)
  } catch {}
}

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state')
    if (serializedState === null) {
      return undefined
    }

    let loadedState = JSON.parse(serializedState)
    return loadedState
  } catch (err) {
    return undefined
  }
}

export const store = configureStore(loadState())
store.subscribe(() => {
  saveState({
    wallet: store.getState().wallet,
  })
})

export const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <WalletHandler />
        <ScrollToTop />
        <ProgressBar />
        <AppContainer>
          <Menu />
          <VersionDebugger />
          <Switch>
            <Route exact path="/">
              <Landing />
            </Route>
            <Route exact path="/pack">
              <Pack />
            </Route>
            <Route exact path="/marketplace">
              <Marketplace />
            </Route>
            <Route exact path="/card/:uniqueId">
              <CardExtended />
            </Route>
            <Route exact path="/profile">
              <Profile />
            </Route>
            <Route exact path="/challenge">
              <Challenge />
            </Route>
            <Route exact path="/sales-history">
              <SalesHistory />
            </Route>
            <Route exact path="/activities">
              <Activities />
            </Route>
            <Route exact path="/collection/sloeb">
              <Collection />
            </Route>
          </Switch>
          <Footer />
        </AppContainer>
        <Toaster />
        <Modal />
      </Router>
    </Provider>
  )
}
