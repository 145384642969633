/* eslint-disable react-hooks/exhaustive-deps */
import * as PropTypes from 'prop-types'
import * as React from 'react'
import { useEffect, useState } from 'react'

import { TimerView } from './Timer.view'

type TimerProps = {
  deadline: string
}

export const Timer = ({ deadline }: TimerProps) => {
  const toSecond = 1000,
    toMinute = toSecond * 60,
    toHour = toMinute * 60

  const [strings, setStrings] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  })

  const countDown = new Date(deadline).getTime()

  function setTimers() {
    const now = new Date().getTime()
    const distance = countDown - now

    setStrings({
      hours: Math.floor(distance / toHour),
      minutes: Math.floor((distance % toHour) / toMinute),
      seconds: Math.floor((distance % toMinute) / toSecond),
    })
  }
  useEffect(() => {
    setTimers()
    const interval = setInterval(() => {
      setTimers()
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  let deadlineDate = new Date(deadline)
  let today = new Date()
  let active = true

  if (deadlineDate < today) {
    active = false
  }

  return <TimerView seconds={strings.seconds} minutes={strings.minutes} hours={strings.hours} active={active} />
}

Timer.propTypes = {
  deadline: PropTypes.string.isRequired,
}

Timer.defaultProps = {
  deadline: undefined,
}
