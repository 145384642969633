// prettier-ignore
import * as React from 'react'
import { useSelector } from 'react-redux'
import { State } from '../../../reducers/index'
import { TransactionModalView } from './TransactionModal.view'

export const TransactionModal = () => {
  const version = useSelector((state: State) => state.version.number)
  const wallet = useSelector((state: State) => state.wallet)
  return <TransactionModalView version={version} wallet={wallet} />
}
