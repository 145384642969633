import { Button } from '../Button/Button.view'
import {
  CheckoutModalButtonLogo,
  CheckoutModalButtonsContainer,
  CheckoutModalContainer,
  CheckoutModalFirstLine,
  CheckoutModalHowTo,
  CheckoutModalLeft,
  CheckoutModalRight,
  CheckoutModalSecondLine,
  CheckoutModalSeparator,
  CheckoutModalStyled,
  CheckoutModalUsd,
} from './CheckoutModal.style'

interface CheckoutModalViewProps {
  version: number
  type: 'booster' | 'card'
  number: number
}

export const CheckoutModalView = ({ version, type, number }: CheckoutModalViewProps) => {
  let rows = []
  let usdPrice = 10
  for (let i = 0; i < number; i++) {
    rows.push(
      <CheckoutModalSecondLine>
        <CheckoutModalLeft>{type === 'booster' ? 'BOOSTER - 3 CARDS' : 'PACK - 1 CARD'}</CheckoutModalLeft>
        <CheckoutModalRight>{type === 'booster' ? 300 : 100} MYTV</CheckoutModalRight>
      </CheckoutModalSecondLine>,
    )
  }
  return (
    <CheckoutModalStyled>
      <CheckoutModalContainer>
        <CheckoutModalFirstLine>Total</CheckoutModalFirstLine>
        <CheckoutModalSeparator />
        {rows}
        <CheckoutModalUsd>~ {usdPrice * number} USD</CheckoutModalUsd>
        <CheckoutModalHowTo>How to use metamask ?</CheckoutModalHowTo>
        <CheckoutModalButtonsContainer>
          <Button appearance={'primary_empty_gray'} width={'100%'} margin={false}>
            <CheckoutModalButtonLogo src="/images/checkout/metamask.png" alt="" />
          </Button>
          <Button appearance={'primary_empty_gray'} width={'100%'} margin={false}>
            <CheckoutModalButtonLogo src="/images/checkout/paypal.png" alt="" />
          </Button>
          <Button appearance={'primary_empty_gray'} width={'100%'} margin={false}>
            <CheckoutModalButtonLogo src="/images/checkout/credit_card.png" alt="" />
          </Button>
        </CheckoutModalButtonsContainer>
      </CheckoutModalContainer>
    </CheckoutModalStyled>
  )
}
