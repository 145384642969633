import {
  TableLineStyled,
  TableLineImg,
  TableLineDescriptive,
  TableLineTxt,
  TableLineLogo,
  TableLineSeparator,
} from './TableLine.style'
import { activityType, activityState } from '../Activities.enum'
import { Button } from 'app/App.components/Button/Button.view'
import { Timer } from 'app/App.components/Timer/Timer.controller'
import { useHistory } from 'react-router'
import { CardAction } from 'app/App.components/CardAction/CardAction.controller'
import { ModalType } from 'app/App.components/CardAction/CardAction.enum'

interface TableLineViewProps {
  id: number
  img: string
  price: number
  date: Date
  name: string
  tokenId: string
  contractAddress: string
  type: activityType
  state: number
  deadline: string
  showModalImg: (title: string, children: JSX.Element, image: string) => void
}

export const TableLineView = ({
  id,
  img,
  price,
  date,
  name,
  tokenId,
  contractAddress,
  type,
  state,
  deadline,
  showModalImg,
}: TableLineViewProps) => {
  let day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
  let month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  let year = date.getFullYear() % 1000

  let button
  const history = useHistory()

  switch (state) {
    case activityState.BID:
      button = (
        <Button appearance={'primary_empty'} width={type === activityType.AUCTIONS ? '100%' : '175px'}>
          BID
        </Button>
      )
      break
    case activityState.ACCEPT:
      button = (
        <Button
          appearance={'primary_empty'}
          width={type === activityType.AUCTIONS ? '100%' : '175px'}
          clickCallback={() =>
            showModalImg(
              'Accept this offer',
              <CardAction owner={''} collection={''} type={ModalType.ACCEPT} item={[]} />,
              '/images/marketplace/cards/loeb.png',
            )
          }
        >
          ACCEPT
        </Button>
      )
      break
    case activityState.CANCEL:
      button = (
        <Button
          appearance={'orange_empty'}
          width={type === activityType.AUCTIONS ? '100%' : '175px'}
          clickCallback={() => history.push(`/card/${id}`)}
        >
          CANCEL
        </Button>
      )
      break
    case activityState.VIEW:
      button = (
        <Button
          appearance={'gray_empty'}
          width={type === activityType.AUCTIONS ? '100%' : '175px'}
          clickCallback={() => history.push(`/card/${id}`)}
        >
          VIEW
        </Button>
      )
      break
    case activityState.FINALIZE:
      button = (
        <Button appearance={'primary_empty'} width={type === activityType.AUCTIONS ? '100%' : '175px'}>
          FINALIZE
        </Button>
      )
      break
    default:
  }
  return (
    <TableLineStyled>
      <TableLineImg src={img} alt={name + ' image'} />
      <TableLineDescriptive>{type === activityType.AUCTIONS ? 'Latest bid price' : 'Price'}</TableLineDescriptive>
      <TableLineTxt>{price} MYTV</TableLineTxt>
      <TableLineDescriptive>{type === activityType.AUCTIONS ? 'Time left' : 'Date'}</TableLineDescriptive>
      <TableLineTxt>
        {type === activityType.AUCTIONS ? <Timer deadline={deadline} /> : `${day}/${month}/${year}`}
      </TableLineTxt>
      <TableLineDescriptive>Name</TableLineDescriptive>
      <TableLineTxt>{name}</TableLineTxt>
      <TableLineDescriptive>Token ID</TableLineDescriptive>
      <TableLineTxt
        onClick={() => {
          navigator.clipboard.writeText(tokenId)
        }}
      >
        {tokenId}
        <TableLineLogo src="/images/address.svg" alt="" />
      </TableLineTxt>
      <TableLineDescriptive>Contract address</TableLineDescriptive>
      <TableLineTxt
        onClick={() => {
          navigator.clipboard.writeText(contractAddress)
        }}
      >
        {contractAddress}
        <TableLineLogo src="/images/address.svg" alt="" />
      </TableLineTxt>
      <TableLineDescriptive gridColumn={'1'}>{type === activityType.AUCTIONS ? 'State' : ''}</TableLineDescriptive>
      <TableLineTxt type={type}>{button}</TableLineTxt>
      <TableLineSeparator className="separator" />
    </TableLineStyled>
  )
}
