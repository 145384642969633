import { Button } from 'app/App.components/Button/Button.view'
import { useState } from 'react'
import { WalletState } from 'reducers/wallet'
import { Image } from 'styles'

import { activityType } from './Activities.enum'
import {
  ActivitiesButton,
  ActivitiesButtonBackground,
  ActivitiesButtonsContainer,
  ActivitiesContainer,
  ActivitiesFirstLine,
  ActivitiesGrid,
  ActivitiesGridFirstCase,
  ActivitiesNotConnectedContainer,
  ActivitiesNotConnectedImg,
  ActivitiesNotConnectedLeft,
  ActivitiesNotConnectedRight,
  ActivitiesNotConnectedTitle,
  ActivitiesResults,
  ActivitiesSecondLine,
  ActivitiesSelect,
  ActivitiesSelectArrow,
  ActivitiesSelectContainer,
  ActivitiesStyled,
  ActivitiesTh,
  ActivitiesTitle,
} from './Activities.style'
import { TableLine } from './TableLine/TableLine.controller'

interface ActivitiesViewProps {
  version: number
  wallet: WalletState
  connectWallet: () => void
}

export const ActivitiesView = ({ version, wallet, connectWallet }: ActivitiesViewProps) => {
  const [type, setType] = useState(activityType.AUCTIONS)
  return (
    <ActivitiesStyled>
      <ActivitiesFirstLine>
        <ActivitiesTitle>Activities</ActivitiesTitle>
        <ActivitiesButtonsContainer>
          <ActivitiesButtonBackground
            selected={type === activityType.AUCTIONS}
            onClick={() => setType(activityType.AUCTIONS)}
          >
            <ActivitiesButton>Auctions</ActivitiesButton>
          </ActivitiesButtonBackground>
          <ActivitiesButtonBackground
            selected={type === activityType.LISTING}
            onClick={() => setType(activityType.LISTING)}
          >
            <ActivitiesButton>Listing</ActivitiesButton>
          </ActivitiesButtonBackground>
        </ActivitiesButtonsContainer>
      </ActivitiesFirstLine>
      {wallet.address !== '' && (
        <ActivitiesContainer>
          <ActivitiesSecondLine>
            <ActivitiesResults>1234 results</ActivitiesResults>
            <ActivitiesSelectContainer>
              <ActivitiesSelect>
                <option selected disabled>
                  Filter by
                </option>
                <option>...</option>
                <option>...</option>
                <option>...</option>
              </ActivitiesSelect>
              <ActivitiesSelectArrow alt="search icon" src={`/images/marketplace/arrow-down.svg`} />
              <Image position={'absolute'} top={34} right={24} />
            </ActivitiesSelectContainer>
          </ActivitiesSecondLine>
          <ActivitiesGrid>
            <ActivitiesGridFirstCase />
            <ActivitiesTh>{type === activityType.AUCTIONS ? 'Latest bid price' : 'Price'}</ActivitiesTh>
            <ActivitiesTh>{type === activityType.AUCTIONS ? 'Time left' : 'Date'}</ActivitiesTh>
            <ActivitiesTh>Name</ActivitiesTh>
            <ActivitiesTh>Token ID</ActivitiesTh>
            <ActivitiesTh>Contract address</ActivitiesTh>
            <ActivitiesTh>{type === activityType.AUCTIONS ? 'State' : ''}</ActivitiesTh>
            <TableLine id={1} type={type}></TableLine>
            <TableLine id={2} type={type}></TableLine>
            <TableLine id={3} type={type}></TableLine>
            <TableLine id={4} type={type}></TableLine>
            <TableLine id={5} type={type}></TableLine>
            <TableLine id={6} type={type}></TableLine>
          </ActivitiesGrid>
        </ActivitiesContainer>
      )}

      {wallet.address === '' && (
        <ActivitiesNotConnectedContainer>
          <ActivitiesNotConnectedLeft>
            <ActivitiesNotConnectedTitle>
              Please connect wallet to access the functionality
              <Button appearance={'primary'} width={'221px'} margin={false} clickCallback={connectWallet}>
                CONNECT WALLET
              </Button>
            </ActivitiesNotConnectedTitle>
          </ActivitiesNotConnectedLeft>
          <ActivitiesNotConnectedRight>
            <ActivitiesNotConnectedImg src="/images/activities-not-connected.png" alt="" />
          </ActivitiesNotConnectedRight>
        </ActivitiesNotConnectedContainer>
      )}
    </ActivitiesStyled>
  )
}
