import {
  SalesHistoryStyled,
  SalesHistoryContainer,
  SalesHistoryFirstLine,
  SalesHistoryTitle,
  SalesHistorySecondLine,
  SalesHistoryResults,
  SalesHistorySelect,
  SalesHistoryGrid,
  SalesHistoryTh,
  SalesHistorySelectContainer,
  SaleHistoryGridFirstCase,
} from './SalesHistory.style'

import { Image } from '../../styles'
import { TableLine } from './TableLine/TableLine.controller'

interface SalesHistoryViewProps {
  version: number
}

export const SalesHistoryView = ({ version }: SalesHistoryViewProps) => {
  return (
    <SalesHistoryStyled>
      <SalesHistoryFirstLine>
        <SalesHistoryTitle>Sales History</SalesHistoryTitle>
      </SalesHistoryFirstLine>
      <SalesHistoryContainer>
        <SalesHistorySecondLine>
          <SalesHistoryResults>1234 results</SalesHistoryResults>
          <SalesHistorySelectContainer>
            <SalesHistorySelect>
              <option selected disabled>
                Filter by
              </option>
              <option>...</option>
              <option>...</option>
              <option>...</option>
            </SalesHistorySelect>
            <Image
              position={'absolute'}
              top={34}
              right={24}
              alt="search icon"
              src={`/images/marketplace/arrow-down.svg`}
            />
          </SalesHistorySelectContainer>
        </SalesHistorySecondLine>
        <SalesHistoryGrid>
          <SaleHistoryGridFirstCase />
          <SalesHistoryTh>Price</SalesHistoryTh>
          <SalesHistoryTh>Date</SalesHistoryTh>
          <SalesHistoryTh>Name</SalesHistoryTh>
          <SalesHistoryTh>Token ID</SalesHistoryTh>
          <SalesHistoryTh>Contract address</SalesHistoryTh>
          <SalesHistoryTh>Sale type</SalesHistoryTh>
          <SalesHistoryTh>Tx hash</SalesHistoryTh>
          <TableLine id={1}></TableLine>
          <TableLine id={2}></TableLine>
          <TableLine id={3}></TableLine>
          <TableLine id={4}></TableLine>
          <TableLine id={5}></TableLine>
          <TableLine id={6}></TableLine>
        </SalesHistoryGrid>
      </SalesHistoryContainer>
    </SalesHistoryStyled>
  )
}
