import { Box } from 'styles'

import { LoadingBar } from '../LoadingBar/LoadingBar.view'
import { AmountSold, InfoContainer, InfoContainerMobile, PhasesContainer, PhaseTitle } from './InfoComponent.style'
import { SubInfo } from './SubInfo/SubInfo.view'

interface InfoComponentProps {
  mobileVersion: Boolean
  series: string
  uniqueNfts: string
  myTvchain: string,
  staring: string,
  mintDate: string,
  maxAmountPerUser: string,
  numberSold: string
}

export const InfoComponent = ({ mobileVersion, series, uniqueNfts, myTvchain, staring, mintDate, maxAmountPerUser, numberSold }: InfoComponentProps) => {
  return (
    <>
      {mobileVersion ?
        <InfoContainerMobile>
          <PhasesContainer>
            <Box display='flex' flexDirection='column'>
              <PhaseTitle>Private Sale</PhaseTitle>
              <LoadingBar maxWidth='148px' width='21vw' loadingPercent={100} />
            </Box>
            <Box display='flex' flexDirection='column'>
              <PhaseTitle>Fan2Earn Sale</PhaseTitle>
              <LoadingBar maxWidth='148px' width='21vw' loadingPercent={0} />
            </Box>
            <Box display='flex' flexDirection='column'>
              <PhaseTitle>Public Sale</PhaseTitle>
              <LoadingBar maxWidth='148px' width='21vw' loadingPercent={0} />
            </Box>
          </PhasesContainer>
          <Box marginLeft={15} marginTop={40} marginBottom={40}>
            <SubInfo title={'Unique NFTs'} value={uniqueNfts} />
            <SubInfo title={'MyTVchain'} value={myTvchain} />
            <SubInfo title={'Staring'} value={staring} />
            <SubInfo title={'Mint date'} value={mintDate} />
            <SubInfo title={'Max amount per user'} value={maxAmountPerUser} />
          </Box>
          <Box display='flex' flexDirection='column' marginBottom={42} alignItems='center'>
            <LoadingBar maxWidth='492px' width='90vw' loadingPercent={0} />
            <AmountSold>{numberSold}/2004 sold</AmountSold>
          </Box>
        </InfoContainerMobile>
        :
        <InfoContainer>
          {series === 'premium' &&
            <PhasesContainer>
              <Box display='flex' flexDirection='column'>
                <PhaseTitle>Private Sale</PhaseTitle>
                <LoadingBar maxWidth='148px' width='11.5vw' loadingPercent={100} />
              </Box>
              <Box display='flex' flexDirection='column'>
                <PhaseTitle>Fan2Earn Sale</PhaseTitle>
                <LoadingBar maxWidth='148px' width='11.5vw' loadingPercent={0} />
              </Box>
              <Box display='flex' flexDirection='column'>
                <PhaseTitle>Public Sale</PhaseTitle>
                <LoadingBar maxWidth='148px' width='11.5vw' loadingPercent={0} />
              </Box>
            </PhasesContainer>
          }
          {series === 'fan' &&
            <PhasesContainer>
              <Box display='flex' flexDirection='column'>
                <PhaseTitle>Public Sale</PhaseTitle>
                <LoadingBar maxWidth='492px' width='90vw' loadingPercent={100} />
              </Box>
            </PhasesContainer>
          }
          <Box marginLeft={85} marginTop={40} marginBottom={40}>
            <SubInfo title={'Unique NTSs'} value={uniqueNfts} />
            <SubInfo title={'MyTVchain'} value={myTvchain} />
            <SubInfo title={'Staring'} value={staring} />
            <SubInfo title={'Mint date'} value={mintDate} />
            <SubInfo title={'Max amount per user'} value={maxAmountPerUser} />
          </Box>
          <Box display='flex' flexDirection='column' marginBottom={42} alignItems='center'>
            <LoadingBar maxWidth='492px' width='33vw' loadingPercent={0} />
            <AmountSold>{numberSold}/2004 sold</AmountSold>
          </Box>
        </InfoContainer>
      }
    </>
  )
}
