import styled, { css } from 'styled-components/macro'
import { Breakpoints } from 'styles/breakpoints'
import { activityType } from '../Activities.enum'

export const TableLineStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-column: 1/9;
  grid-row-gap: 36px;
  @media (max-width: ${Breakpoints.lg}) {
    grid-template-columns: repeat(4, 1fr);
    grid-column: unset;
    grid-row-gap: 8px;
    padding: 32px 39px 0px 39px;

    &:nth-child(even) {
      border-right: 1px solid #61616b;
    }

    &:nth-last-child(1),
    &:nth-last-child(2) {
      > .separator {
        border-bottom: none;
      }
    }
  }

  @media (max-width: ${Breakpoints.md}) {
    padding: 32px 32px 0px 52px;
    &:nth-child(even) {
      border-right: none;
    }
  }
`
export const TableLineImg = styled.img`
  height: 80px;
  margin: auto;
  @media (max-width: ${Breakpoints.lg}) {
    grid-column: 1/5;
    margin: auto;
    height: 160px;
    margin-bottom: 24px;
  }
`

export const TableLineDescriptive = styled.div<{ gridColumn?: string }>`
  font-family: Circe;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */

  color: #9542ff;

  display: none;

  @media (max-width: ${Breakpoints.lg}) {
    display: block;
    text-align: left;
    grid-column: ${(props) => (props.gridColumn ? props.gridColumn : '1/3')};
  }
`
export const TableLineTxt = styled.div<{ type?: activityType }>`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-family: Circe;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;

  /* identical to box height */

  color: #ffffff;

  @media (max-width: ${Breakpoints.lg}) {
    font-size: 18px;
    line-height: 27px;
    font-weight: normal;
    justify-content: flex-start;
    text-align: left;

    > span {
      margin: 0;
      max-width: unset;
    }

    ${(props) => {
      if (props.type !== undefined) {
        if (props.type === activityType.AUCTIONS) {
          return css`
            grid-column: 2/5;

            > span {
              margin-left: 50px;
            }
          `
        } else {
          return css`
            grid-column: 1/5;
            justify-content: center;
          `
        }
      }
      return css`
        grid-column: 3/5;
      `
    }}
  }

  @media (max-width: ${Breakpoints.md}) {
    ${(props) => {
      if (props.type === activityType.AUCTIONS) {
        return css`
          > span {
            margin-left: 70px;
            margin-right: 15px;
          }
        `
      }
      return
    }}
  }
`

export const TableLineLogo = styled.img`
  width: 17px;
  height: 17px;
  margin-left: 10px;
  cursor: pointer;
`

export const TableLineSeparator = styled.div`
  grid-column: 1/9;
  width: 100%;
  border-bottom: 1px solid #61616b;

  @media (max-width: ${Breakpoints.lg}) {
    grid-column: 1/5;
    margin-top: 32px;
  }
`
