import { Button, ButtonProps } from 'app/App.components/Button/Button.view'
import { CheckoutModal } from 'app/App.components/CheckoutModal/CheckoutModal.controller'
import { Slider } from 'pages/Pack/Slider/Slider.view'
import { useState } from 'react'

// prettier-ignore
import { BackgroundShapeLeft, BackgroundShapeRight, Border, CardsContainer, ComingSoon, ComingSoonResponsive, ComingSoonTablet, ContainerDesktop, ContainerResponsive, FirstCard, FirstCardContainer, FirstCardFan, Image, ImgLessOrMore, LeftSkewContainer, MissingBorderBottom, MissingBorderTop, PackGrid, PackNumber, PackNumberContainer, PackPrice, PackSelectionContainer, PackShadow, PackStyled, PackTopContainer, RightSkewContainer, SecondCard, SecondCardContainer, SecondCardFan, TextNumber } from './Pack.style'

enum Side {
  Left,
  Right,
  None,
}

interface PackViewProps {
  version: number
  showModal: (title: string, children: JSX.Element) => void
}

export const PackView = ({ version, showModal }: PackViewProps) => {
  const [selected, setSelected] = useState('')
  const [numberOfCards, setNumberOfCards] = useState(1)
  const [numberOfBoosters, setNumberOfBoosters] = useState(1)
  const [rightButtonStyle, setRightButtonStyle] = useState<ButtonProps>({
    appearance: 'primary_empty',
    children: undefined,
  })

  const [leftButtonStyle, setLeftButtonStyle] = useState<ButtonProps>({
    appearance: 'primary_empty',
    children: undefined,
  })

  function selectSide(side: Side) {
    if (side === Side.Left) {
      setSelected('LEFT')
      setLeftButtonStyle({ appearance: 'primary', children: undefined })
    } else if (side === Side.Right) {
      setSelected('RIGHT')
      setRightButtonStyle({ appearance: 'primary', children: undefined })
    } else {
      setSelected('')
      setLeftButtonStyle({ appearance: 'primary_empty', children: undefined })
      setRightButtonStyle({ appearance: 'primary_empty', children: undefined })
    }
  }

  let cards = [
    <FirstCardContainer
      onMouseEnter={() => {
        selectSide(Side.Left)
      }}
      onMouseLeave={() => {
        selectSide(Side.None)
      }}
      selected={selected === 'LEFT'}
    >
      <LeftSkewContainer selected={selected === 'LEFT'} />
      <MissingBorderBottom selected={selected === 'LEFT'} />
      {version === 0 && (
        <>
          <ComingSoon width={97.8} bottom={65} left={0} src="/images/packs/coming-soon-left.png" alt="" />
          <ComingSoonResponsive src="/images/packs/coming-soon-responsive.png" alt="" />
        </>
      )}
      <PackShadow src="/images/packs/shadow-left.png" alt="" selected={selected === 'LEFT'} />
      <FirstCard selected={selected === 'LEFT'}>
        <CardsContainer>
          <FirstCardFan selected={selected === 'LEFT'}>
            <img alt="card" src="/images/blurry-card-1.png" />
          </FirstCardFan>
          <img alt="black card" src="/images/black-card.png" />
          <Image alt="card" src="/images/card.png" selected={selected === 'LEFT'} />
        </CardsContainer>
        <h1>PACK - 1 CARD</h1>
        <PackSelectionContainer>
          <PackNumberContainer>
            <ImgLessOrMore
              src="/images/collection/less.svg"
              alt=""
              onClick={() => {
                if (numberOfCards > 1) {
                  setNumberOfCards(numberOfCards - 1)
                }
              }}
            />
            <PackNumber>
              <TextNumber
                type="number"
                value={numberOfCards}
                onChange={(e: any) => setNumberOfCards(parseInt(e.target.value))}
              />
            </PackNumber>
            <ImgLessOrMore
              src="/images/collection/more.svg"
              alt=""
              onClick={() => setNumberOfCards(numberOfCards + 1)}
            />
          </PackNumberContainer>
          <Border />
          <PackPrice>...BNB</PackPrice>
        </PackSelectionContainer>
        <Button
          width="240px"
          appearance={leftButtonStyle.appearance}
          clickCallback={() => showModal('Complete checkout', <CheckoutModal type="card" number={numberOfCards} />)}
          disabled={numberOfCards <= 0}
        >
          BUY A CARD
        </Button>
      </FirstCard>
    </FirstCardContainer>,
    <SecondCardContainer
      onMouseEnter={() => {
        selectSide(Side.Right)
      }}
      onMouseLeave={() => {
        selectSide(Side.None)
      }}
      selected={selected === 'RIGHT'}
    >
      <RightSkewContainer selected={selected === 'RIGHT'} />
      <MissingBorderTop selected={selected === 'RIGHT'} />
      {version === 0 && (
        <>
          <ComingSoon width={103.3} bottom={90} left={-22} src="/images/packs/coming-soon-right.png" alt="" />
          <ComingSoonResponsive src="/images/packs/coming-soon-responsive.png" alt="" />
        </>
      )}
      <PackShadow src="/images/packs/shadow-right.png" alt="" selected={selected === 'RIGHT'} />
      <SecondCard selected={selected === 'RIGHT'}>
        <CardsContainer>
          <SecondCardFan selected={selected === 'RIGHT'}>
            <img alt="card" src="/images/blurry-card-1.png" />
            <img alt="card" src="/images/blurry-card-2.png" />
            <img alt="card" src="/images/blurry-card-3.png" />
          </SecondCardFan>
          <img alt="black card" src="/images/black-card.png" />
          <Image alt="card" src="/images/card.png" selected={selected === 'RIGHT'} />
        </CardsContainer>
        <h1>BOOSTER - 3 CARDS</h1>
        <PackSelectionContainer>
          <PackNumberContainer>
            <ImgLessOrMore
              src="/images/collection/less.svg"
              alt=""
              onClick={() => {
                if (numberOfBoosters > 1) {
                  setNumberOfBoosters(numberOfBoosters - 1)
                }
              }}
            />
            <PackNumber>
              <TextNumber
                type="number"
                value={numberOfBoosters}
                onChange={(e: any) => setNumberOfBoosters(parseInt(e.target.value))}
              />
            </PackNumber>
            <ImgLessOrMore
              src="/images/collection/more.svg"
              alt=""
              onClick={() => setNumberOfBoosters(numberOfBoosters + 1)}
            />
          </PackNumberContainer>
          <Border />
          <PackPrice>...BNB</PackPrice>
        </PackSelectionContainer>
        <Button
          width="240px"
          appearance={rightButtonStyle.appearance}
          clickCallback={() =>
            showModal('Complete checkout', <CheckoutModal type="booster" number={numberOfBoosters} />)
          }
          disabled={numberOfBoosters <= 0}
        >
          BUY A BOOSTER
        </Button>
      </SecondCard>
    </SecondCardContainer>,
  ]
  return (
    <PackTopContainer>
      <PackStyled>
        <BackgroundShapeLeft />
        <BackgroundShapeRight />
        <PackGrid>
          {version === 0 && <ComingSoonTablet src="images/packs/coming-soon-tablet.png" alt="" />}
          <ContainerDesktop>{cards}</ContainerDesktop>
          <ContainerResponsive>
            <Slider>{cards}</Slider>
          </ContainerResponsive>
        </PackGrid>
      </PackStyled>
    </PackTopContainer>
  )
}
