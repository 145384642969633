import styled from 'styled-components/macro'
import { Breakpoints } from 'styles/breakpoints'

import { ButtonStyled } from '../Button/Button.style'

export const EmailStyled = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: ${Breakpoints.md}) {
    flex-direction: column;
    align-items: center;
  }
`

export const EmailInput = styled.input<{ backgroundColorInput?: String }>`
  position: relative;
  z-index: 2;
  background: ${(props) => (props.backgroundColorInput ? `${props.backgroundColorInput}` : `#17171a`)};
  width: 480px;
  height: 48px;
  padding: 0px 15px 0px 15px;
  max-width: 100%;
  border: 0px;
  margin-bottom: 24px;

  font-family: Fira Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  ::placeholder {
    font-family: Circe;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: rgba(248, 250, 252, 0.5);
  }

  @media (max-width: ${Breakpoints.xl}) {
    width: 330px;
    font-size: 14px;
  }

  @media (max-width: 950px) {
    width: 200px;
  }

  @media (max-width: ${Breakpoints.md}) {
    width: 326px;
    font-size: 16px;
    margin-bottom: 16px;
  }
`

export const EmailInputSkew = styled(EmailInput)`
  position: absolute;
  z-index: 1;
  right: -11px;
  pointer-events: none;
  transform: skew(-21deg, 0);
  width: 40px;
`

export const InputsContainer = styled.div`
  position: relative;
`

export const ButtonSignUp = styled(ButtonStyled)`
  width: 160px;
  min-width: 0px;
  height: 48px;

  font-family: Circe;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  color: #f8fafc;

  @media (max-width: ${Breakpoints.xl}) {
    width: 120px;
    font-size: 14px;
  }

  @media (max-width: 950px) {
    width: 77px;
    font-size: 11px;
  }

  @media (max-width: ${Breakpoints.md}) {
    width: 300px;
    font-size: 16px;
    margin-bottom: 48px;
  }
`

export const ButtonInside = styled.div`
  width: 160px;
  position: relative;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  margin-top: 2px;
  margin-left: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: skew(21deg);
`