import { Bar } from './LoadingBar.style'

interface LoadingBarProps {
  maxWidth: string
  width: string
  loadingPercent: number
}

export const LoadingBar = ({ maxWidth, width, loadingPercent }: LoadingBarProps) => {
  return (
    <Bar maxWidth={maxWidth} width={width}>
      <Bar maxWidth={maxWidth} width={width} loadingPercent={loadingPercent} />
    </Bar>
  )
}
