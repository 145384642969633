import styled, { css } from 'styled-components/macro'

export const BadgeStyled = styled.span<{ type: string }>`
  ${(props) => {
    let backgroundColor = ''

    switch (props.type) {
      case 'common':
        backgroundColor = 'linear-gradient(94.18deg, #5A3E2A 0%, #7A583F 100%)'
        break
      case 'rare':
        backgroundColor = 'linear-gradient(97.27deg, #AC7035 0%, #E2964A 100%)'
        break
      case 'epic':
        backgroundColor = 'linear-gradient(98.41deg, #8F8F8F 0%, #C8C6C6 100%)'
        break
      case 'heroic':
        backgroundColor = 'linear-gradient(94.18deg, #DB9F2B 0%, #EFCB4A 100%)'
        break
      case 'legendary':
        backgroundColor = 'linear-gradient(94.18deg, #4BB5CD 0%, #8FEAFF 100%)'
        break
      default:
        break
    }

    return css`
      background: ${backgroundColor};
    `
  }}
  width: fit-content;
  height: fit-content;
  border-radius: 50px;
  padding: 4px 16px 4px 16px;

  font-family: Fira Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;

  color: #f8fafc;
`
