import { WalletState } from 'reducers/wallet'
import { ModalType } from './CardAction.enum'

import { Sale } from './ActionType/Sale/Sale.controller'
import { MakeOffer } from './ActionType/MakeOffer/MakeOffer.controller'
import { Bid } from './ActionType/Bid/Bid.controller'
import { UpAuction } from './ActionType/UpAuction/UpAuction.controller'
import { Accept } from './ActionType/Accept/Accept.controller'

interface CardActionViewProps {
  version: number
  owner: string
  collection: string
  wallet: WalletState
  type: ModalType
  updateModal: (title: string, children: JSX.Element) => void
  item: any
}

export const CardActionView = ({
  version,
  owner,
  collection,
  wallet,
  type,
  updateModal,
  item,
}: CardActionViewProps) => {
  let component = <></>
  switch (type) {
    case ModalType.BID:
      component = <Bid owner={owner} collection={collection} item={item} />
      break
    case ModalType.MAKE_OFFER:
      component = <MakeOffer owner={owner} collection={collection} item={item} />
      break
    case ModalType.SALE:
      component = <Sale owner={owner} collection={collection} item={item} />
      break
    case ModalType.UP_AUCTION:
      component = <UpAuction owner={owner} collection={collection} item={item} />
      break
    case ModalType.ACCEPT:
      component = <Accept subTotal={2500} />
      break
  }

  if (version >= 1) {
    return component
  } else {
    return <></>
  }
}
