import { Button } from 'app/App.components/Button/Button.view'
import { ComingSoonImg } from 'app/App.components/ComingSoonImg/ComingSoonImg.view'
import { MarketplaceCard } from 'app/App.components/Marketplace/MarketplaceCard/MarketplaceCard.controller'
//import { useState } from 'app/App.components/Marketplace/MarketplaceCard/MarketplaceCard.controller'
import { useState } from 'react'
import { Loader } from 'app/App.components/Loader/Loader.view'
import { Image } from '../../styles'
import {
  ButtonContainer,
  CardsContainer,
  DeleteFiltersButton,
  FilterButton,
  FiltersContainer,
  FiltersContainerResponsive,
  FiltersContainerScrollResponsive,
  FilterSelector,
  FilterSelectorSearch,
  FilterSelectorSearchContainer,
  FilterSelectorSelect,
  FiltersSelectorContainer,
  FilterStatusButton,
  FilterStatusContainer,
  MarketplaceContainer,
  MarketplaceHeader,
  MarketplaceSearch,
  MarketplaceSearchBorderBox,
  MarketplaceSearchContainer,
  MarketplaceStyled,
  MinMaxContainer,
  MinMaxInput,
  ResponsiveFilterButton,
  ResultsNumber,
} from './Marketplace.style'
import { NftEntity } from 'Entities'

interface Filter {
  type: string
  label: string
  query: string
}

interface MarketPlaceViewProps {
  version: number
  tokens: NftEntity[]
  filters: Filter[]
  removeOneFilter: (query: string) => void
  addOneFilter: (filter: Filter) => void
  removeAllFilters: () => void
  addFilters: (filters: Filter[]) => void
  loading: boolean
}
export const MarketplaceView = ({
  version,
  tokens,
  filters,
  removeOneFilter,
  addOneFilter,
  removeAllFilters,
  addFilters,
  loading,
}: MarketPlaceViewProps) => {
  const baseMarketplaceImageUrl = '/images/marketplace/'
  const [showing, setShowing] = useState(false)

  let filterPool: Filter[] = []

  function addFilterPool(filter: Filter) {
    filterPool.push(filter)
    launchFilterPool()
  }

  let timeout: any
  function launchFilterPool() {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      if (filterPool.length === 1) {
        addOneFilter(filterPool[0])
      } else if (filterPool.length >= 2) {
        addFilters(filterPool)
      }
    }, 1000)
  }

  return (
    <MarketplaceStyled>
      <MarketplaceHeader>
        <MarketplaceSearchBorderBox>
          <MarketplaceSearchContainer>
            <MarketplaceSearch placeholder="Search"></MarketplaceSearch>
            <Image
              position={'absolute'}
              top={13}
              right={24}
              alt="search icon"
              src={`${baseMarketplaceImageUrl}/ionic-ios-search.svg`}
            />
            <ResultsNumber>1234 Results</ResultsNumber>
          </MarketplaceSearchContainer>
        </MarketplaceSearchBorderBox>
        <FiltersContainer>
          {filters.map((filter, id) => {
            return (
              <FilterButton key={id}>
                {filter.label}
                <Image
                  style={{ cursor: 'pointer' }}
                  marginLeft={8}
                  alt="close selected filter"
                  src={`${baseMarketplaceImageUrl}/ionic-ios-close.svg`}
                  onClick={() => {
                    removeOneFilter(filter.type)
                  }}
                />
              </FilterButton>
            )
          })}

          {filters.length >= 1 && (
            <DeleteFiltersButton onClick={() => removeAllFilters()}>Delete filters</DeleteFiltersButton>
          )}
        </FiltersContainer>
        <ResponsiveFilterButton onClick={() => setShowing(!showing)}>
          <Image
            style={{ cursor: 'pointer' }}
            alt="Filter button"
            src={`${baseMarketplaceImageUrl}/filter_icon.svg`}
          ></Image>
        </ResponsiveFilterButton>
      </MarketplaceHeader>
      <FiltersContainerScrollResponsive>
        <FiltersContainerResponsive>
          <FilterButton>
            Status : New
            <Image
              style={{ cursor: 'pointer' }}
              marginLeft={8}
              alt="close selected filter"
              src={`${baseMarketplaceImageUrl}/ionic-ios-close.svg`}
            />
          </FilterButton>
          <FilterButton>
            From $0 to $150
            <Image
              style={{ cursor: 'pointer' }}
              marginLeft={8}
              alt="close selected filter"
              src={`${baseMarketplaceImageUrl}/ionic-ios-close.svg`}
            />
          </FilterButton>
          <FilterButton>
            Auction
            <Image
              style={{ cursor: 'pointer' }}
              marginLeft={8}
              alt="close selected filter"
              src={`${baseMarketplaceImageUrl}/ionic-ios-close.svg`}
            />
          </FilterButton>
          <DeleteFiltersButton>Delete filters</DeleteFiltersButton>
        </FiltersContainerResponsive>
      </FiltersContainerScrollResponsive>
      <MarketplaceContainer>
        <FiltersSelectorContainer showing={showing}>
          <FilterSelector>
            <h3>Status</h3>
            <FilterStatusContainer>
              <FilterStatusButton
                onClick={() => addFilterPool({ type: 'buy_now', query: 'query_buy_now', label: 'Buy Now' })}
              >
                Buy Now
              </FilterStatusButton>
              <FilterStatusButton
                onClick={() => addFilterPool({ type: 'auction', query: 'query_auction', label: 'Auction' })}
              >
                Auction
              </FilterStatusButton>
              <FilterStatusButton onClick={() => addFilterPool({ type: 'new', query: 'query_new', label: 'New' })}>
                New
              </FilterStatusButton>
              <FilterStatusButton
                onClick={() =>
                  addFilterPool({ type: 'limited_time', query: 'query_limite_time', label: 'Limited Time' })
                }
              >
                Limited Time
              </FilterStatusButton>
            </FilterStatusContainer>
          </FilterSelector>
          <FilterSelector>
            <h3>Filter</h3>
            <FilterSelectorSearchContainer>
              <FilterSelectorSelect placeholder="Sort by">
                <option disabled>Sort by</option>
                <option>New - Old</option>
                <option>Old - New</option>
                <option>A - Z</option>
                <option>Z - A</option>
              </FilterSelectorSelect>
              <Image
                position={'absolute'}
                top={34}
                right={24}
                alt="search icon"
                src={`${baseMarketplaceImageUrl}/arrow-down.svg`}
              />
            </FilterSelectorSearchContainer>
          </FilterSelector>
          <FilterSelector>
            <h3>Price</h3>
            <FilterSelectorSearchContainer>
              <FilterSelectorSelect placeholder="Sort by">
                <option disabled>Sort by</option>
                <option>Low - High</option>
                <option>High - Low</option>
              </FilterSelectorSelect>
              <Image
                position={'absolute'}
                top={34}
                right={24}
                alt="search icon"
                src={`${baseMarketplaceImageUrl}/arrow-down.svg`}
              />
            </FilterSelectorSearchContainer>
            <MinMaxContainer>
              <MinMaxInput placeholder="Min"></MinMaxInput>à<MinMaxInput placeholder="Max"></MinMaxInput>
            </MinMaxContainer>
          </FilterSelector>
          <FilterSelector>
            <h3>Creator</h3>
            <FilterSelectorSearchContainer>
              <FilterSelectorSearch placeholder="Search"></FilterSelectorSearch>
              <Image
                position={'absolute'}
                top={29}
                right={24}
                alt="search icon"
                src={`${baseMarketplaceImageUrl}/ionic-ios-search.svg`}
              />
            </FilterSelectorSearchContainer>
          </FilterSelector>
          <FilterSelector>
            <h3>Collection</h3>
            <FilterSelectorSearchContainer>
              <FilterSelectorSearch placeholder="Search"></FilterSelectorSearch>
              <Image
                position={'absolute'}
                top={29}
                right={24}
                alt="search icon"
                src={`${baseMarketplaceImageUrl}/ionic-ios-search.svg`}
              />
            </FilterSelectorSearchContainer>
          </FilterSelector>
          <FilterSelector>
            <h3>Sport</h3>
            <FilterSelectorSearchContainer>
              <FilterSelectorSearch placeholder="Search"></FilterSelectorSearch>
              <Image
                position={'absolute'}
                top={29}
                right={24}
                alt="search icon"
                src={`${baseMarketplaceImageUrl}/ionic-ios-search.svg`}
              />
            </FilterSelectorSearchContainer>
          </FilterSelector>
          <ButtonContainer>
            <Button appearance="primary">SEARCH</Button>
          </ButtonContainer>
        </FiltersSelectorContainer>
        {loading && <Loader />}
        {version >= 1 && tokens !== undefined && !loading && (
          <CardsContainer>
            {tokens.map((item: any) => {
              return <MarketplaceCard tokenInfos={item} key={item.id}></MarketplaceCard>
            })}
          </CardsContainer>
        )}
        {version === 0 && <ComingSoonImg margin={[200, 85]} />}
      </MarketplaceContainer>
    </MarketplaceStyled>
  )
}
