export enum Breakpoints {
  xxs = '0px',
  xs = '320px',
  sm = '576px',
  md = '767px',
  lg = '1023px',
  xl = '1200px',
  xxl = '1450px',
  xxxl = '1800px',
  quatrek = '2000px'
}