/* eslint-disable react-hooks/exhaustive-deps */
import { connectWallet } from 'actions/Wallet.actions'
// prettier-ignore
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { State } from '../../reducers/index'
import { ProfileView } from './Profile.view'
import * as Webservices from 'Webservices'
import { ITokenSupportBalance } from 'Webservices/Erc20/Balances'

export const Profile = () => {
  const version = useSelector((state: State) => state.version.number)
  const wallet = useSelector((state: State) => state.wallet)
  const [personnalCollection, setPersonnalCollection] = useState<ITokenSupportBalance[]>([])
  const [loading, setLoading] = useState(true)

  async function loadPersonnalCollection() {
    let res = await Webservices.Erc20.Balances.get(null, { userAddress: wallet.address })
    setPersonnalCollection(res)
    setLoading(false)
  }

  useEffect(() => {
    if (wallet.address !== '') {
      setLoading(true)
      loadPersonnalCollection()
    }
  }, [wallet])

  const dispatch = useDispatch()
  const connectWalletCb = () => {
    dispatch(connectWallet())
  }
  return (
    <ProfileView
      version={version}
      wallet={wallet}
      personnalCollection={personnalCollection}
      loading={loading}
      connectWallet={connectWalletCb}
    />
  )
}
