import { TokenSupportEntity } from 'Entities'
import BaseService from 'Webservices/BaseService'

type IParamsGet = [] | null

type IQueryString = {
  userAddress: string
  tokenAddress?: string
}

export interface ITokenSupportBalance {
  name: TokenSupportEntity['name']
  address: TokenSupportEntity['address']
  symbol: TokenSupportEntity['symbol']
  decimals: TokenSupportEntity['decimals']
  balance: string
}

export default class Balances extends BaseService {
  protected static readonly webServiceUrl = new URL(`${process.env.REACT_APP_API_BASEPATH}/erc20/balances`)

  public static async get(params: IParamsGet , queryString: IQueryString): Promise<ITokenSupportBalance[]> {
    Object.entries(queryString).forEach(([key, value]) => Balances.webServiceUrl.searchParams.set(key, value))
    return (await this.httpGet(Balances.webServiceUrl)).json()
  }
}
