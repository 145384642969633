import styled from 'styled-components/macro'

export const VersionDebuggerStyled = styled.div`
  width: 80px;
  position: absolute;
  z-index: 1;
  top: 90px;
  left: 10px;
  cursor: pointer;
`

export const VersionDebuggerContainer = styled.div``

export const VersionDebuggerSelect = styled.select`
  width: 100%;
  height: 30px;
  text-align: center;
  font-size: 16px;
  border-radius: 50px;
  background: #232327;
  color: white;
  -webkit-appearance: none;
  -moz-appearance: none;

  font-family: Fira Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
`
