/* eslint-disable react-hooks/exhaustive-deps */
import { SET_ADDRESS, SET_WEB3 } from 'actions/Wallet.actions'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'reducers'
import Web3 from 'web3'
import Web3Modal from 'web3modal'
export const WalletHandler = () => {
  const dispatch = useDispatch()
  const wallet = useSelector((state: State) => state.wallet)
  const [isListening, setIsListening] = useState(false)

  async function loadWallet() {
    // If you were already connected, we need to config the web3 and the provider again
    if (wallet.address !== '' && wallet.provider === undefined) {
      // Creating a web3modal provider instance
      const web3Modal = new Web3Modal()

      // If the instance was cached
      if (web3Modal.cachedProvider === 'injected') {
        // Recreating the provider by connecting to it
        const provider = await web3Modal.connect()
        const web3 = new Web3(provider)

        // Dispatching the informations
        web3.eth.getAccounts((error, accounts) => {
          dispatch({
            type: SET_WEB3,
            address: accounts[0],
            currencyAmount: 0,
            web3,
            provider,
          })
        })
      }
    }
  }

  function listenWallet(provider: any) {
    provider
      .on('accountsChanged', (accounts: string[]) => {
        const web3 = new Web3(provider)
        web3.eth.getAccounts((error, accounts) => {
          dispatch({
            type: SET_ADDRESS,
            address: accounts[0],
          })
        })
      })
      .on('chainChanged', (chainId: any) => {
        chainId = parseInt(chainId, 16)
        console.log(chainId)
      })
  }
  useEffect(() => {
    loadWallet()
    if (wallet.provider !== undefined && !isListening) {
      setIsListening(true)
      listenWallet(wallet.provider)
    }
  }, [wallet])
  return <></>
}
