// prettier-ignore
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../../reducers/index'
import { CardActionView } from './CardAction.view'
import { ModalType } from './CardAction.enum'
import { updateModal } from '../Modal/Modal.actions'

interface CardActionProps {
  owner: string
  collection: string
  type: ModalType
  item: any
}

export const CardAction = ({ owner, collection, type, item }: CardActionProps) => {
  const dispatch = useDispatch()

  const version = useSelector((state: State) => state.version.number)
  const wallet = useSelector((state: State) => state.wallet)

  const updateModalCb = (title: string, children: JSX.Element) => {
    dispatch(updateModal(title, children))
  }
  return (
    <CardActionView
      version={version}
      owner={owner}
      collection={collection}
      wallet={wallet}
      type={type}
      updateModal={updateModalCb}
      item={item}
    />
  )
}
