import styled from 'styled-components/macro'
import { Page } from 'styles'
import { Breakpoints } from 'styles/breakpoints'

export const MarketplaceStyled = styled(Page)`
  width: 100%;
  text-align: center;
  padding: 0;
`

export const MarketplaceHeader = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid #61616b;

  @media (max-width: ${Breakpoints.lg}) {
    justify-content: space-between;
  }
`

export const MarketplaceSearchContainer = styled.div`
  position: relative;
  margin: 24px 68px 16px 40px;
  display: flex;
  flex-direction: column;
  width: 255px;

  @media (max-width: ${Breakpoints.lg}) {
    margin: 24px 0px 24px 34px;
  }
`
export const MarketplaceSearch = styled.input`
  background: #232327;
  border-radius: 32px;
  width: 255px;
  height: 42.79px;
  padding: 0px 15px 0px 15px;
  border: 0px;

  font-family: Fira Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  ::placeholder {
    color: rgba(248, 250, 252, 0.5);
    opacity: 1;
    font-family: Fira Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
  }

  @media (max-width: ${Breakpoints.lg}) {
    width: 100%;
  }

  @media (min-width: ${Breakpoints.lg}) {
    width: 100%;
  }
`

export const ResultsNumber = styled.span`
  font-family: Circe;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;

  color: #f8fafc;

  margin-top: 8px;

  @media (max-width: ${Breakpoints.lg}) {
    display: none;
  }
`

export const FiltersContainer = styled.div`
  display: flex;
  margin-left: 40px;
  @media (max-width: ${Breakpoints.lg}) {
    display: none;
  }
`

export const FiltersContainerScrollResponsive = styled.div`
  display: none;
  border-bottom: 1px solid #61616b;
  max-width: 100%;
  width: 100%;
  overflow-y: scroll;
  text-align: left;
  @media (max-width: ${Breakpoints.lg}) {
    display: inline-block;
  }
`

export const FiltersContainerResponsive = styled.div`
  width: max-content;
`

export const FilterButton = styled.button`
  background: #4934c2;
  box-shadow: 0px 0px 24px #4934c2;
  border-radius: 32px;

  font-family: Fira Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  color: #f8fafc;
  height: 42.79px;

  border: 0px;
  margin: 24px 12px;

  padding: 12px 24px;
`

export const DeleteFiltersButton = styled(FilterButton)`
  cursor: pointer;
  background: none;
  box-shadow: none;
  padding-left: 0px;
`

export const FiltersSelectorContainer = styled.div<{ showing: boolean }>`
  border-right: 1px solid #61616b;
  width: 330px;
  text-align: center;
  padding: 0;
  display: flex;
  flex-direction: column;

  transition: height 0.5s ease-in-out;
  @media (max-width: ${Breakpoints.lg}) {
    position: absolute;
    z-index: 2;
    width: 100%;
    background: #171717;
    border: 0px;

    overflow: hidden;
    z-index: 11;
    height: ${(props) => (props.showing ? '985px' : '0px')};
  }
`
export const FilterSelector = styled.div`
  text-align: left;
  padding-left: 40px;
  padding-right: 40px;
  border-bottom: 1px solid #61616b;
  > h3 {
    font-family: Fira Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin-top: 24px;
    color: #f8fafc;
  }
`

export const FilterSelectorSearchContainer = styled.div`
  position: relative;
  display: inline-block;

  @media (max-width: ${Breakpoints.lg}) {
    width: 100%;
  }
`

export const FilterSelectorSearch = styled(MarketplaceSearch)`
  border-radius: 5px;
  margin-top: 16px;
  margin-bottom: 24px;
`

export const FilterSelectorSelect = styled.select`
  background: #232327;
  border-radius: 5px;

  width: 255px;
  height: 42.79px;
  padding: 0px 15px 0px 15px;
  border: 0px;

  font-family: Fira Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  margin-top: 16px;
  margin-bottom: 24px;

  color: rgba(248, 250, 252, 0.5);

  -webkit-appearance: none;
  -moz-appearance: none;

  @media (max-width: ${Breakpoints.lg}) {
    width: 100%;
  }
`

export const FilterStatusContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-top: 8px;
  margin-bottom: 24px;
  margin-right: -16px;
`
export const FilterStatusButton = styled.button`
  width: calc(50% - 8px);
  height: 41px;
  text-align: center;
  background: #232327;
  border-radius: 8px;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;

  color: #f8fafc;
  border: 0px;
  margin-top: 8px;
  cursor: pointer;
  margin-right: 8px;
`

export const ButtonContainer = styled.div`
  margin-top: 25px;
  margin-bottom: 40px;

  @media (max-width: ${Breakpoints.lg}) {
    margin-left: auto;
    margin-right: auto;
  }
`

export const MinMaxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  color: #f8fafc;
  margin-bottom: 24px;
  width: 255px;

  @media (max-width: ${Breakpoints.lg}) {
    width: 100%;
  }
`
export const MinMaxInput = styled(FilterSelectorSearch)`
  width: 105px;
  margin: 0px;

  @media (max-width: ${Breakpoints.lg}) {
    width: 45%;
  }
`

export const MarketplaceContainer = styled.div`
  display: flex;
  width: 100%;
`

export const CardsContainer = styled.div`
  display: grid;
  grid-gap: 60px;
  width: calc(100vw - 331px);
  padding: 38px 90px;

  grid-template-columns: repeat(6, 1fr);

  @media (max-width: ${Breakpoints.quatrek}) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (max-width: ${Breakpoints.xxxl}) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: ${Breakpoints.xxl}) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: ${Breakpoints.xxl}) {
    padding: 38px 45px;
  }

  @media (max-width: ${Breakpoints.lg}) {
    width: 100%;
    grid-gap: 48px;
    padding: 32px;
  }

  @media (max-width: ${Breakpoints.md}) {
    grid-gap: 24px;
  }

  @media (max-width: ${Breakpoints.sm}) {
    grid-gap: 16px;
    padding: 24px 32px;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${Breakpoints.xs}) {
    grid-gap: 16px;
    padding: 12px 16px;
    grid-template-columns: repeat(2, 1fr);
  }
`

export const ResponsiveFilterButton = styled.button`
  background: none;
  border: 0;
  display: none;
  margin-right: 34px;

  @media (max-width: ${Breakpoints.lg}) {
    display: block;
  }
`
export const MarketplaceSearchBorderBox = styled.div`
  width: 330px;
  border-right: 1px solid #61616b;

  @media (max-width: ${Breakpoints.lg}) {
    border-right: none;
  }
`

export const RemoveButton = styled.img`

`