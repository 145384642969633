export enum activityType {
  AUCTIONS,
  LISTING,
}

export enum activityState {
  BID = 0,
  VIEW = 1,
  FINALIZE = 2,
  ACCEPT = 3,
  CANCEL = 4,
}
