import styled from 'styled-components/macro'
import { Breakpoints } from 'styles/breakpoints'

export const TableLineStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-column: 1/9;
  grid-row-gap: 36px;

  @media (max-width: ${Breakpoints.lg}) {
    grid-template-columns: repeat(2, 1fr);
    grid-column: unset;
    grid-row-gap: 8px;
    padding: 32px 48px 0px 48px;

    &:nth-child(even) {
      border-right: 1px solid #61616b;
    }

    &:nth-last-child(1),
    &:nth-last-child(2) {
      > .separator {
        border-bottom: none;
      }
    }
  }

  @media (max-width: ${Breakpoints.md}) {
    &:nth-child(even) {
      border-right: none;
    }
  }
`
export const TableLineImg = styled.img`
  height: 80px;
  margin: auto;

  @media (max-width: ${Breakpoints.lg}) {
    grid-column: 1/3;
    margin: auto;
    height: 160px;
    margin-bottom: 24px;
  }
`

export const TableLineDescriptive = styled.div`
  font-family: Circe;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */

  color: #9542ff;

  display: none;
  @media (max-width: ${Breakpoints.lg}) {
    display: block;
    text-align: left;
  }
`
export const TableLineTxt = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-family: Circe;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */

  color: #ffffff;

  @media (max-width: ${Breakpoints.lg}) {
    justify-content: flex-start;
  }
`

export const TableLineLogo = styled.img`
  width: 17px;
  height: 17px;
  margin-left: 10px;
  cursor: pointer;
`

export const TableLineSeparator = styled.div`
  grid-column: 1/9;
  width: 100%;
  border-bottom: 1px solid #61616b;

  @media (max-width: ${Breakpoints.lg}) {
    grid-column: 1/3;
    margin-top: 32px;
  }
`
