import {
  TableLineStyled,
  TableLineImg,
  TableLineDescriptive,
  TableLineTxt,
  TableLineLogo,
  TableLineSeparator,
} from './TableLine.style'

interface TableLineViewProps {
  img: string
  price: number
  date: Date
  name: string
  tokenId: string
  contractAddress: string
  saleType: string
  txHash: string
}

export const TableLineView = ({
  img,
  price,
  date,
  name,
  tokenId,
  contractAddress,
  saleType,
  txHash,
}: TableLineViewProps) => {
  let day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
  let month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  let year = date.getFullYear() % 1000
  return (
    <TableLineStyled>
      <TableLineImg src={img} alt={name + ' image'} />
      <TableLineDescriptive>Price</TableLineDescriptive>
      <TableLineTxt>{price} MYTV</TableLineTxt>
      <TableLineDescriptive>Date</TableLineDescriptive>
      <TableLineTxt>{`${day}/${month}/${year}`}</TableLineTxt>
      <TableLineDescriptive>Name</TableLineDescriptive>
      <TableLineTxt>{name}</TableLineTxt>
      <TableLineDescriptive>Token ID</TableLineDescriptive>
      <TableLineTxt
        onClick={() => {
          navigator.clipboard.writeText(tokenId)
        }}
      >
        {tokenId}
        <TableLineLogo src="/images/address.svg" alt="" />
      </TableLineTxt>
      <TableLineDescriptive>Contract address</TableLineDescriptive>
      <TableLineTxt
        onClick={() => {
          navigator.clipboard.writeText(contractAddress)
        }}
      >
        {contractAddress}
        <TableLineLogo src="/images/address.svg" alt="" />
      </TableLineTxt>
      <TableLineDescriptive>Sale type</TableLineDescriptive>
      <TableLineTxt>{saleType}</TableLineTxt>
      <TableLineDescriptive>Tx hash</TableLineDescriptive>
      <TableLineTxt>
        {txHash}
        <TableLineLogo src="/images/exit.svg" alt="" />
      </TableLineTxt>
      <TableLineSeparator className="separator" />
    </TableLineStyled>
  )
}
