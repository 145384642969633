function formatAddress(address: string) {
  if (address.length <= 10) {
    return address
  }
  return address.substring(0, 6) + '...' + address.substring(address.length - 4, address.length)
}

function getIpfsLink(link: string) {
  let tokenUri = link.split('ipfs://')[1]
  if (tokenUri !== undefined) {
    tokenUri = link.split('ipfs:/')[1]
    if(tokenUri !== undefined){
      return process.env.REACT_APP_IPFS_GATEWAY + tokenUri
    }
    return link
  } else {
    if(isUrl(link)){
      return link
    }else{
      return process.env.REACT_APP_IPFS_GATEWAY + '/' + link
    }
  }
}

function isUrl(link: string) {
  if (link.includes('http://') || link.includes('https://')) {
    return true
  } else {
    return false
  }
}

function shuffleArray(array: any[]) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    const temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }
  return array
}
export { formatAddress, getIpfsLink, isUrl, shuffleArray }
