/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
// prettier-ignore
import { showModal } from 'app/App.components/Modal/Modal.actions'
import { NftEntity } from 'Entities'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getIpfsLink, isUrl, shuffleArray } from 'utils'
import * as Webservices from 'Webservices'
import { State } from '../../reducers/index'
import { CardExtendedView } from './CardExtended.view'
import useApi from 'hooks/use.api'

export const CardExtended = () => {
  const dispatch = useDispatch()
  let showModalCb = (title: string, children: JSX.Element) => {
    dispatch(showModal(title, children))
  }
  const version = useSelector((state: State) => state.version.number)
  const api = useApi()
  const params: any = useParams()

  const [cardInfos, setCardInfos] = useState<NftEntity | null>(null)
  const [imageLink, setImageLink] = useState('')
  const [sameCollection, setSameCollection] = useState<NftEntity[]>([])

  async function getCardInfos() {
    // Getting a single card
    let data = await Webservices.Erc721.Tokens.get(null, { q: { _id: params.uniqueId } })

    // If the card exists
    if (data[0] !== undefined) {
      // Setting the card infos
      setCardInfos(data[0])
      let tokenInfos = data[0]

      // Getting the IPFS infos of the card
      let tokenDataRes = await api.getRaw(getIpfsLink(tokenInfos.tokenUri))

      // If the image is a URL, setting the URL else format with the IPFS gateway
      if (isUrl(tokenDataRes.data.image)) {
        setImageLink(tokenDataRes.data.image)
      } else {
        setImageLink(getIpfsLink(tokenDataRes.data.image))
      }
    }
  }

  async function getSameCollection() {
    // Getting the NFTs of the same collection
    if (cardInfos !== undefined && sameCollection.length === 0) {
      let sameCollection = await Webservices.Erc721.Tokens.get(null, {
        q: { tokenAddress: (cardInfos as any).tokenAddress },
      })

      if (sameCollection.length > 5) {
        setSameCollection(shuffleArray(sameCollection).slice(0, 5))
      } else {
        setSameCollection(sameCollection)
      }
    }
  }

  useEffect(() => {
    getCardInfos()
  }, [params])

  useEffect(() => {
    getSameCollection()
  }, [cardInfos])

  return (
    <CardExtendedView
      version={version}
      showModal={showModalCb}
      cardInfos={cardInfos}
      imageLink={imageLink}
      sameCollection={sameCollection}
    />
  )
}
