import styled from 'styled-components/macro'
import { Page } from 'styles'
import { Breakpoints } from 'styles/breakpoints'

export const SalesHistoryStyled = styled.div`
  margin-top: 80px;

  @media (max-width: ${Breakpoints.lg}) {
    margin-top: 160px;
  }

  @media (max-width: ${Breakpoints.md}) {
    margin-top: 80px;
  }
`

export const SalesHistoryContainer = styled(Page)`
  margin: 0;
  padding: 0 100px;

  @media (max-width: ${Breakpoints.lg}) {
    padding: 0;
  }
`

export const SalesHistoryFirstLine = styled.div`
  display: flex;
  height: 160px;

  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #61616b;

  @media (max-width: ${Breakpoints.lg}) {
    height: 94px;
  }

  @media (max-width: ${Breakpoints.md}) {
    border: none;
    height: fit-content;
    padding-top: 32px;
    padding-bottom: 20px;
  }
`

export const SalesHistoryTitle = styled.h1`
  display: flex;
  align-items: center;
  font-family: Termina;
  font-style: normal;
  font-weight: bold;
  font-size: 38px;
  line-height: 46px;
  /* identical to box height */

  text-transform: uppercase;

  color: #f8fafc;
  text-align: left;
  width: fit-content;
  margin: 0;
  padding: 0 100px;
  height: 100%;
  border-right: 1px solid #61616b;

  @media (max-width: ${Breakpoints.lg}) {
    font-size: 24px;
    line-height: 29px;
    width: 50%;
    padding: 0 0 0 32px;
  }

  @media (max-width: ${Breakpoints.md}) {
    border: none;
    width: 100%;
    justify-content: center;
    padding: 0;
  }
`

export const SalesHistorySecondLine = styled.div`
  display: flex;
  height: 111px;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: ${Breakpoints.lg}) {
    padding-left: 32px;
  }

  @media (max-width: ${Breakpoints.md}) {
    justify-content: center;
    height: fit-content;
  }
`

export const SalesHistoryResults = styled.div`
  margin-right: 40px;
  font-family: Circe;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #f8fafc;
`

export const SalesHistorySelectContainer = styled.div`
  position: relative;
`

export const SalesHistorySelect = styled.select`
  background: #232327;
  border-radius: 5px;

  width: 135px;
  height: 42.79px;
  padding: 12px 24px;
  border: 0px;

  font-family: Fira Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  margin-top: 16px;
  margin-bottom: 24px;

  color: rgba(248, 250, 252, 0.5);

  -webkit-appearance: none;
  -moz-appearance: none;
`

export const SalesHistoryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);

  grid-row-gap: 36px;

  text-align: center;

  padding-bottom: 130px;

  @media (max-width: ${Breakpoints.lg}) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0;
  }

  @media (max-width: ${Breakpoints.md}) {
    grid-template-columns: 1fr;
  }
`
export const SaleHistoryGridFirstCase = styled.div`
  @media (max-width: ${Breakpoints.lg}) {
    display: none;
  }
`

export const SalesHistoryTh = styled.div`
  font-family: Circe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */

  color: #9542ff;

  @media (max-width: ${Breakpoints.lg}) {
    display: none;
  }
`
