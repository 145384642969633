import useSendinblueContactApi from 'hooks/use.sendinblue'
import { useCallback, useState } from 'react'

import { ButtonInside, ButtonSignUp, EmailInput, EmailInputSkew, EmailStyled, InputsContainer } from './EmailComponent.style'

interface EmailComponentProps {
  buttonText: string
  backgroundColorInput?: string
  skew: Boolean
  emailListIds: number[]
}
const SibApiV3Sdk = require('@sendinblue/client')

export const EmailComponent = ({ buttonText, backgroundColorInput, skew, emailListIds }: EmailComponentProps) => {
  const [email, setEmail] = useState('')

  const sendinblueContactApi = useSendinblueContactApi()

  const addEmailToSendinBlue = useCallback(
    async (email: string) => {
      let createContact = new SibApiV3Sdk.CreateContact()
      createContact.email = email
      createContact.listIds = emailListIds
      sendinblueContactApi.createContact(createContact).then(
        function (data: any) {
          setEmail('')
        },
        function (error: any) {
          console.error(error)
        },
      )
    },
    [sendinblueContactApi, emailListIds, setEmail],
  )

  return (
    <EmailStyled>
      <InputsContainer>
        <EmailInput
          backgroundColorInput={backgroundColorInput}
          value={email}
          placeholder="E-mail"
          type={'email'}
          onChange={(e) => {
            setEmail(e.target.value)
          }}
        ></EmailInput>
        {skew ? (
          <EmailInputSkew
            backgroundColorInput={backgroundColorInput}
            value={email}
            type={'email'}
            onChange={(e) => {
              setEmail(e.target.value)
            }}
          ></EmailInputSkew>
        ) : (
          <></>
        )}
      </InputsContainer>
      <ButtonSignUp margin={true} appearance="primary" disabled={false} onClick={() => addEmailToSendinBlue(email)}>
        <ButtonInside>{buttonText}</ButtonInside>
      </ButtonSignUp>
    </EmailStyled>
  )
}
