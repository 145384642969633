import styled, { css } from 'styled-components/macro'
import { Page } from 'styles'
import { Breakpoints } from 'styles/breakpoints'

export const ActivitiesStyled = styled.div`
  margin-top: 80px;

  @media (max-width: ${Breakpoints.lg}) {
    margin-top: 160px;
  }

  @media (max-width: ${Breakpoints.md}) {
    margin-top: 80px;
  }
`

export const ActivitiesContainer = styled(Page)`
  margin: 0;
  padding: 0 100px;
  margin-top: 40px;
  @media (max-width: ${Breakpoints.lg}) {
    padding: 0;
    margin-top: 0;
  }
`

export const ActivitiesFirstLine = styled.div`
  display: flex;
  height: 160px;

  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #61616b;

  @media (max-width: ${Breakpoints.lg}) {
    height: 94px;
  }

  @media (max-width: ${Breakpoints.md}) {
    border: none;
    height: fit-content;
    padding-top: 32px;
    padding-bottom: 20px;

    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
`

export const ActivitiesButtonsContainer = styled.div`
  margin-left: 40px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;

  @media (max-width: ${Breakpoints.md}) {
    width: calc(100% - 80px);
    margin-left: 0;
    margin-top: 24px;
    grid-gap: 24px;
  }
`
export const ActivitiesButtonBackground = styled.div<{ selected: boolean }>`
  border-radius: 8px;
  padding: 1px 1px;

  ${(props) => {
    if (props.selected) {
      return css`
        background: linear-gradient(97.47deg, rgba(149, 66, 255, 0.52) 14.07%, rgba(83, 188, 239, 0.52) 92.09%);
      `
    } else {
      return css`
        > div {
          opacity: 0.45;
        }
      `
    }
  }}
`

export const ActivitiesButton = styled.div`
  background: #232327;
  border-radius: 8px;

  font-family: Fira Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */

  text-align: center;

  color: #f8fafc;

  padding: 13px 34px;

  cursor: pointer;

  @media (max-width: ${Breakpoints.md}) {
    font-size: 18px;
    line-height: 22px;
    padding: 10px 35px;
  }
`

export const ActivitiesTitle = styled.h1`
  display: flex;
  align-items: center;
  font-family: Termina;
  font-style: normal;
  font-weight: bold;
  font-size: 38px;
  line-height: 46px;
  /* identical to box height */

  text-transform: uppercase;

  color: #f8fafc;
  text-align: left;
  width: fit-content;
  margin: 0;
  padding: 0 191px 0 100px;
  height: 100%;
  border-right: 1px solid #61616b;

  @media (max-width: ${Breakpoints.lg}) {
    font-size: 24px;
    line-height: 29px;
    width: 45%;
    padding: 0 0 0 32px;
  }

  @media (max-width: ${Breakpoints.md}) {
    border: none;
    width: 100%;
    justify-content: center;
    padding: 0;
    padding-left: 32px;
  }
`
export const ActivitiesSecondLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin-bottom: 40px;

  @media (max-width: ${Breakpoints.lg}) {
    padding-left: 32px;
    margin-top: 40px;
  }

  @media (max-width: ${Breakpoints.md}) {
    justify-content: center;
    height: fit-content;
    display: grid;
    padding-left: 0;
    grid-template-columns: repeat(2, 1fr);

    width: calc(100% - 80px);
    margin: auto;
    grid-gap: 24px;
    justify-content: center;
    text-align: center;
  }
`

export const ActivitiesResults = styled.div`
  margin-right: 40px;
  font-family: Circe;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #f8fafc;

  @media (max-width: ${Breakpoints.md}) {
    margin-right: 0;
  }
`

export const ActivitiesSelectContainer = styled.div`
  position: relative;

  @media (max-width: ${Breakpoints.md}) {
    width: 100%;
  }
`

export const ActivitiesSelect = styled.select`
  background: #232327;
  border-radius: 5px;

  width: 135px;
  height: 42.79px;
  padding: 12px 24px;
  border: 0px;

  font-family: Fira Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  margin-top: 16px;
  margin-bottom: 24px;

  color: rgba(248, 250, 252, 0.5);

  -webkit-appearance: none;
  -moz-appearance: none;

  margin: 0;

  @media (max-width: ${Breakpoints.md}) {
    width: 100%;
  }
`
export const ActivitiesSelectArrow = styled.img`
  position: absolute;
  top: 18px;
  right: 18px;
`

export const ActivitiesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);

  grid-row-gap: 36px;

  text-align: center;

  padding-bottom: 130px;

  @media (max-width: ${Breakpoints.lg}) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0;
  }

  @media (max-width: ${Breakpoints.md}) {
    grid-template-columns: 1fr;
  }
`
export const ActivitiesGridFirstCase = styled.div`
  @media (max-width: ${Breakpoints.lg}) {
    display: none;
  }
`

export const ActivitiesTh = styled.div`
  font-family: Circe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */

  color: #9542ff;

  @media (max-width: ${Breakpoints.lg}) {
    display: none;
  }
`
export const ActivitiesNotConnectedContainer = styled.div`
  display: flex;
  align-items: center;
`

export const ActivitiesNotConnectedLeft = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: ${Breakpoints.lg}) {
    right: 0;
    z-index: 1;
    height: calc(100vh - 253px);
    width: 100%;
  }
`
export const ActivitiesNotConnectedRight = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: ${Breakpoints.lg}) {
    position: absolute;
    right: 0;
    top: 253px;
  }
`

export const ActivitiesNotConnectedImg = styled.img`
  height: calc(100vh - 240px);
  @media (max-width: ${Breakpoints.lg}) {
    height: calc(100vh - 253px);
  }
`

export const ActivitiesNotConnectedTitle = styled.h1`
  margin: 0;
  width: 600px;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 44px;
  line-height: 53px;

  color: #f8fafc;

  > span {
    margin-top: 32px;
    margin-left: 15px;
    font-family: Circe;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #f8fafc;
  }

  @media (max-width: ${Breakpoints.lg}) {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
  }
`
