import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'
import { Image } from 'styles'

export const MenuStyled = styled.div`
  font-size: 14px;
  position: fixed;
  top: 0px;
  width: 100%;
  padding: 0 100px;
  z-index: 5;
  background: #212125;

  @media (max-width: 1439px) {
    padding: 0 50px;
  }

  @media (max-width: 1023px) {
    padding: 0 0;
  }

  @media (max-width: 767px) {
    padding: 0 20px;
  }
`

export const MenuBar = styled.div<{ showing: boolean; logged: boolean; menuLength: string }>`
  margin: 0 auto;
  text-align: center;
  height: 80px;
  z-index: 1;
  display: grid;
  grid-template-columns: ${(props) =>
    props.logged
      ? 'auto auto repeat(' + props.menuLength + ', fit-content(100px)) 100px fit-content(0px)'
      : 'auto auto repeat(' + props.menuLength + ', fit-content(100px)) 220px 5px'};
  grid-gap: 32px;
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
  box-sizing: border-box;

  align-items: center;
  > a {
    font-family: Fira Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;

    color: #f8fafc;
    > img {
      margin: 22px 0;
    }
  }

  @media (max-width: 1439px) {
    grid-gap: 20px;
  }

  @media (max-width: 1023px) {
    > a:first-child {
      display: none;
    }

    grid-template-columns: ${(props) =>
    props.logged
      ? 'auto repeat(7, fit-content(100px))'
      : 'auto repeat(' + props.menuLength + ', fit-content(100px)) 180px 5px'};
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 0px;
    grid-column-gap: 20px;
    justify-content: center;
    align-items: center;
    height: 160px;
    button {
      width: fit-content;
      padding: 0 20px;
      margin: 0;
      font-family: Circe;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
    }
  }

  @media (max-width: 767px) {
    a:nth-child(1) {
      display: block;
    }
    height: ${(props) => (props.showing ? '440px' : '80px')};
    grid-template-columns: auto;
    grid-template-rows: 80px 0 repeat(6, 50px);
    transition: height 500ms ease-in-out;
  }
`

export const MenuLogoTablet = styled(Link) <{ connected: string }>`
  display: none;

  @media (max-width: 1023px) {
    display: block;
  }

  @media (max-width: 767px) {
    display: none;
  }
  margin: 0;
  line-height: 0px !important;
  width: 200px;
  grid-column: ${(props) => (props.connected === 'true' ? '1/11' : '1/10')};
  margin: auto;
`

export const MenuLogo = styled.img`
  height: 40px;
  margin: 5px 0px 5px 10px;
  width: 215px;
  display: block;

  @media (max-width: 1229px) {
    display: none;
  }

  @media (max-width: 1023px) {
    display: block;
  }
`

export const MenuLogo2 = styled.img`
  height: 40px;
  margin: 5px 0px 5px 10px;
  width: 33.95px;
  display: block;

  @media (min-width: 1230px) {
    display: none;
  }

  @media (max-width: 1023px) {
    display: none;
  }
`

export const MenuSelector = styled.div<{ selected: string }>`
  position: absolute;
  top: 20px;
  left: 0px;
  width: 100px;
  height: 40px;
  box-shadow: inset -5px -5px 10px #fafbff, inset 5px 5px 10px #a6abbd;
  border-radius: 20px;
  transform: translateX(0px);
  transition: transform 500ms ease-in-out;

  ${(props) => {
    console.log(props.selected)
    let position = 0
    switch (props.selected) {
      case '':
        position = 0
        break
      case '#home':
        position = 0
        break
      case '#product':
        position = 110
        break
      case '#clap':
        position = 220
        break
      case '#roadmap':
        position = 330
        break
      case '#team':
        position = 440
        break
      case '#community':
        position = 550
        break
      default:
        position = 0
    }

    return css`
      transform: translateX(${position}px);
    `
  }}

  @media (max-width: 1023px) {
    margin: 0 70px;
    width: calc(100% - 130px);
    transform: translateY(200px);

    ${(props) => {
    console.log(props.selected)
    let position = 0
    switch (props.selected) {
      case '':
        position = 0
        break
      case '#home':
        position = 0
        break
      case '#product':
        position = 60
        break
      case '#clap':
        position = 120
        break
      case '#roadmap':
        position = 180
        break
      case '#team':
        position = 240
        break
      case '#community':
        position = 300
        break
      default:
        position = 0
    }

    return css`
        transform: translateY(${position}px);
      `
  }}
  }
`

export const ConnectButton = styled.button`
  font-family: Circe;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  text-transform: uppercase;

  color: #f8fafc;

  border: 0;
  transform: skew(-21deg);
  background: linear-gradient(95.19deg, #4934c3 5.74%, #3ad8ed 100%);

  align-self: center;
  height: 48px;
  cursor: pointer;

  width: 100%;
  margin: auto;

  @media (max-width: 767px) {
    display: none;
  }
`

export const ConnectButtonText = styled.div`
  transform: skew(21deg);
`

export const ConnectedButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 37px;

  @media (max-width: 767px) {
    display: none;
  }
`

export const CustomNavbarButton = styled.a`
  cursor: pointer;
`

export const SubMenuContainer = styled.div<{ showing: boolean }>`
  position: absolute;
  width: 135px;
  background: #232327;
  top: 80px;
  display: ${(props) => (props.showing ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  transform: translatex(-90px);
  padding: 20px 16px;

  @media (max-width: 1023px) {
    top: 160px;
    transform: translateX(-30px);
  }

  @media (max-width: 767px) {
    top: 80px;
  }
`

export const SubMenuElement = styled.div`
  width: 100%;
  font-family: Circe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: right;

  color: #f8fafc;

  padding: 8px 0px;

  cursor: pointer;
`

export const WalletBalance = styled(Link)`
  font-family: Circe !important;
  color: #9542ff !important;

  @media (max-width: 767px) {
    display: none;
  }
`

export const WalletAddress = styled.a`
  cursor: pointer;
  font-family: Circe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px !important;
  color: #f8fafc;

  @media (max-width: 1023px) {
    display: none;
  }
`

export const AddressImage = styled(Image)`
  margin: auto !important;
  padding-left: 10px;
`

export const ProfileImage = styled(Image)`
  position: relative;

  @media (max-width: 767px) {
    display: none;
  }
`

export const CollectionLink = styled(Link)`
  background: #79F593;
  border-radius: 40px;
  padding: 8px 16px;
  > div{
    font-family: Fira Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    text-transform: uppercase;
    color: #294D42;
  }
`
