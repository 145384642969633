// prettier-ignore
import { updateModal } from 'app/App.components/Modal/Modal.actions'
import { TransactionModal } from 'app/App.components/TransactionModal/TransactionModal.controller'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'reducers'
import * as Webservices from 'Webservices'

import { SaleView } from './Sale.view'

interface SaleProps {
  owner: string
  collection: string
  item: any
}

export const Sale = ({ owner, collection, item }: SaleProps) => {
  const dispatch = useDispatch()

  const version = useSelector((state: State) => state.version.number)
  const wallet = useSelector((state: State) => state.wallet)

  const updateModalCb = (title: string, children: JSX.Element) => {
    dispatch(updateModal(title, children))
  }

  const sellToken = async (amount: number) => {
    dispatch(updateModal('Transaction running', <TransactionModal />))
    let now = new Date()
    let inAYear = new Date(now.getDate() + 365)
    let orderObj = {
      sellerAddress: wallet.address,
      sellTokenAddress: item.tokenSupport.address,
      sellTokenId: item.tokenId,
      sellTokenAmount: 1,
      askTokenAddress: '0x8a682cC16dF6574801Ae578c3858F0daC44398C7',
      askTokenId: 0,
      askTokenAmount: amount,
      startTime: now.getTime(),
      expirationTime: inAYear.getTime(),
      salt: 0,
      sellAssetClass: 3,
      askAssetClass: 3,
      orderHash: 'string',
      orderSignature: 'string',
    }

    let res = await Webservices.Orders.post(orderObj)

    console.log(res)
  }

  console.log(item)
  return (
    <SaleView
      version={version}
      owner={owner}
      collection={collection}
      wallet={wallet}
      updateModal={updateModalCb}
      sellToken={sellToken}
      item={item}
    />
  )
}
