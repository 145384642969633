// prettier-ignore
import { updateModal } from 'app/App.components/Modal/Modal.actions'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'reducers'
import { MakeOfferView } from './MakeOffer.view'

interface MakeOfferProps {
  owner: string
  collection: string
  item: any
}

export const MakeOffer = ({ owner, collection, item }: MakeOfferProps) => {
  const dispatch = useDispatch()

  const version = useSelector((state: State) => state.version.number)
  const wallet = useSelector((state: State) => state.wallet)

  const updateModalCb = (title: string, children: JSX.Element) => {
    dispatch(updateModal(title, children))
  }
  return (
    <MakeOfferView
      version={version}
      owner={owner}
      collection={collection}
      wallet={wallet}
      updateModal={updateModalCb}
      item={item}
    />
  )
}
