/* eslint-disable react-hooks/exhaustive-deps */
import { showModalImg } from 'app/App.components/Modal/Modal.actions'
import useApi from 'hooks/use.api'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getIpfsLink, isUrl } from 'utils'

import { MarketplaceCardView } from './MarketplaceCard.view'

type MarketplaceCardProps = {
  tokenInfos: any
  forSale?: boolean
  grayed?: boolean
  detailsOnHover?: boolean
}

export const MarketplaceCard = ({
  tokenInfos,
  forSale,
  grayed = false,
  detailsOnHover = false,
}: MarketplaceCardProps) => {
  const dispatch = useDispatch()
  const api = useApi()
  const [imageLink, setImageLink] = useState('/images/marketplace/cards/loading.png')
  const [loading, setLoading] = useState(true)

  let showModalCb = (title: string, children: JSX.Element, imageLink: string) => {
    dispatch(showModalImg(title, children, imageLink))
  }

  async function getIpfsInfos() {
    if (tokenInfos.tokenUri) {
      let ipfsUrl = getIpfsLink(tokenInfos.tokenUri)
      let tokenDataRes = await api.getRaw(ipfsUrl)

      if (isUrl(tokenDataRes.data.image)) {
        setImageLink(tokenDataRes.data.image)
      } else {
        setImageLink(getIpfsLink(tokenDataRes.data.image))
      }
    }
    setLoading(false)
  }

  let name = tokenInfos.name ? tokenInfos.name : ''
  let event = '-'
  let number = tokenInfos.tokenId ? tokenInfos.tokenId : 0
  let price = 100
  let priceUsd = 100

  useEffect(() => {
    getIpfsInfos()

    setTimeout(() => {
      setLoading(false)
    }, 10 * 1000)
  }, [tokenInfos])

  return (
    <MarketplaceCardView
      imageLink={imageLink}
      name={name}
      event={event}
      number={number}
      price={price}
      priceUsd={priceUsd}
      forSale={forSale}
      showModal={showModalCb}
      uniqueId={tokenInfos._id}
      grayed={grayed}
      cardItem={tokenInfos}
      detailsOnHover={detailsOnHover}
      loading={loading}
    />
  )
}
