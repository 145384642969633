import { Button } from 'app/App.components/Button/Button.view'
import { useState } from 'react'
import ReactPlayer from 'react-player'
import { Box } from 'styles'

import { BlisterVideoContainer, BlisterVideoContainerMobile, Border, ButtonContainer, CheckBox, PackComponentStyled, PackNumber, PackNumberContainer, PackNumberInput, PackPrice, PackSelectionContainer, ShadedBox, TermsConditionsContainer, TermsConditionsLink, TermsCondtionsText, TextNumber } from './PackComponent.style'

interface PackComponentProps {
  videoUrl?: string
  previewVideoUrl?: string
}

export const PackComponent = ({ videoUrl }: PackComponentProps) => {
  const [shaded, setShaded] = useState(true)
  const [packNumber, setPackNumber] = useState(1)
  function setPackNumberState(qte: number) {
    if (qte > 0 && qte < 16) setPackNumber(qte)
  }

  return (
    <PackComponentStyled>
      <BlisterVideoContainer>
        <ReactPlayer
          url={videoUrl}
          playing
          playsinline
          muted
          loop
          width="180%"
          height="100%"
          style={{ right: '225px', position: 'relative' }}
        ></ReactPlayer>
      </BlisterVideoContainer>
      <BlisterVideoContainerMobile>
        <ReactPlayer
          url={videoUrl}
          playing
          playsinline
          muted
          loop
          width="180%"
          height="100%"
          style={{ right: '130px', position: 'relative' }}
        ></ReactPlayer>
      </BlisterVideoContainerMobile>
      <Box display='flex' flexDirection='column' alignItems='center'>
        <PackSelectionContainer>
          <PackNumberContainer>
            <PackNumberInput type='image' src='/images/collection/less.svg' onClick={() => setPackNumberState(packNumber - 1)} />
            <PackNumber><TextNumber>{packNumber}</TextNumber></PackNumber>
            <PackNumberInput type='image' src='/images/collection/more.svg' onClick={() => setPackNumberState(packNumber + 1)} />
          </PackNumberContainer>
          <Border />
          <PackPrice>...BNB</PackPrice>
        </PackSelectionContainer>
        <ShadedBox shaded={shaded}>
          <TermsConditionsContainer>
            <Box display='flex' flexDirection='row'>
              <CheckBox type='checkbox' onChange={() => setShaded(!shaded)} />
              <TermsCondtionsText>By clicking this box you agree the&nbsp;
                <TermsConditionsLink>General Terms &amp; Conditions of Sale</TermsConditionsLink>
              </TermsCondtionsText>
            </Box>
          </TermsConditionsContainer>
          <ButtonContainer>
            <Button width='240px' appearance='primary'>BUY NFT</Button>
          </ButtonContainer>
        </ShadedBox>
      </Box>
    </PackComponentStyled>
  )
}
