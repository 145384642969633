import { connectWallet, logout } from 'actions/Wallet.actions'
import { useSelector } from 'react-redux'
import { State } from 'reducers'
// prettier-ignore
import * as React from 'react'
import { useDispatch } from 'react-redux'

import { MenuView } from './Menu.view'

export const Menu = () => {
  const dispatch = useDispatch()
  const version = useSelector((state: State) => state.version.number)

  const connectWalletCb = () => {
    dispatch(connectWallet())
  }

  const logoutCallback = () => {
    dispatch(logout())
  }

  return <MenuView connectWallet={connectWalletCb} walletLogout={logoutCallback} version={version} />
}
