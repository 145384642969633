import styled from 'styled-components/macro'
import { Breakpoints } from 'styles/breakpoints'

export const PackComponentStyled = styled.div`
  display: flex;
  flex-direction: column;
  background: rgba(12, 12, 12, 0.82);
  border: 1px solid #61616B;
  backdrop-filter: blur(44px);
  width: 600px;
  height: 633px;
  margin-left: 20px;
  margin-right: 20px;

  @media (max-width: ${Breakpoints.md}) {
    width: 100%;
    border: none;
    margin: auto;
    border-bottom: 1px solid #61616B;
  }
`

export const BlisterVideoContainer = styled.div`
  height: 62.8%;
  width: 100%;
  overflow: hidden;

  @media (max-width: ${Breakpoints.md}) {
    display: none;
  }
`

export const BlisterVideoContainerMobile = styled.div`
  height: 62.8%;
  width: 100%;
  overflow: hidden;

  @media (min-width: ${Breakpoints.md}) {
    display: none;
  }
`

export const PackSelectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  background: linear-gradient(102.62deg, #101010 0%, #19191B 54.69%, #232327 76.86%, #101010 100%);
  height: 71px;
  width: 382px;
  margin-top: 24px;
`

export const PackNumberContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto;
  height: 30px;
`

export const PackNumber = styled.div`
  width: 39px;
  height: 30px;
  background: #36373E;
  margin-left: 21px;
  margin-right: 21px;
`

export const TextNumber = styled.p`
  font-family: 'Circe';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 14px;
  text-align: center;
  color: #F8FAFC;
`

export const Border = styled.div`
  border: 1px solid #FFFFFF;
  margin-left: auto;
  margin-right: auto;
  margin-top: 18px;
  margin-bottom: 18px;
`

export const PackPrice = styled.div`
  font-family: 'Circe';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  text-align: right;
  text-transform: uppercase;
  color: #F8FAFC;
  margin: auto;
`

export const TermsConditionsContainer = styled.div`
  margin-top: 25.5px;
  margin-right: 20px;
  margin-left: 20px;
`

export const TermsCondtionsText = styled.div`
  font-family: 'Circe';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #F8FAFC;
  text-align: left;
`

export const CheckBox = styled.input`
  appearance: none;
  width: 15px;
  height: 15px;
  border: 1.66667px solid #3AD8ED;
  margin-right: 10px;
  background-clip: content-box;
	padding: 2px;

  :checked {
    background-color: #3AD8ED;
  }

`

export const TermsConditionsLink = styled.a`
  text-decoration: underline;
`

export const ButtonContainer = styled.div`
  pointer-events: 'none';
  margin-top: 25.5px;
  margin-bottom: 10px;

  @media (max-width: ${Breakpoints.md}) {
    margin-bottom: 32px;
  }  

`

export const ShadedBox = styled.div<{ shaded: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;

  opacity: ${(props) => props.shaded ? 0.33 : 1};
`

export const PackNumberInput = styled.input`
`