import { Button } from 'app/App.components/Button/Button.view'
import { WalletState } from 'reducers/wallet'
import { TransactionModal } from '../../../TransactionModal/TransactionModal.controller'

import {
  CardActionStyled,
  CardActionSubTitle,
  CardActionInputContainer,
  CardActionInputSkewLeft,
  CardActionInputSkewRight,
  CardActionInput,
  CardActionInputRight,
  CardActionBlueBar,
  CardActionLine,
} from '../../CardAction.style'

import {
  CardActionUpAuctionLineRight,
  CardActionUpAuctionLineLeft,
  CardActionUpAuctionFees,
  CardActionUpAuctionButtonContainer,
  CardActionUpAuctionContainer,
} from './UpAuction.style'

interface CardActionViewProps {
  version: number
  owner: string
  collection: string
  wallet: WalletState
  updateModal: (title: string, children: JSX.Element) => void
  item: any
}

export const UpAuctionView = ({ owner, collection, wallet, updateModal, item }: CardActionViewProps) => {
  return (
    <CardActionStyled>
      <CardActionUpAuctionContainer>
        <CardActionSubTitle>Starting price</CardActionSubTitle>
        <CardActionInputContainer>
          <CardActionInput placeholder="Enter amount" />
          <CardActionInputSkewLeft />
          <CardActionInputRight>MYTV</CardActionInputRight>
          <CardActionInputSkewRight />
        </CardActionInputContainer>
        <CardActionSubTitle>Bidding duration : 24 hours</CardActionSubTitle>
        <CardActionBlueBar src="/images/blue-bar.svg" alt="" />
        <CardActionUpAuctionFees>Fees</CardActionUpAuctionFees>
        <CardActionLine>
          <CardActionUpAuctionLineLeft>Service fees</CardActionUpAuctionLineLeft>
          <CardActionUpAuctionLineRight>2.5%</CardActionUpAuctionLineRight>
        </CardActionLine>
        <CardActionLine>
          <CardActionUpAuctionLineLeft>Creator royalties</CardActionUpAuctionLineLeft>
          <CardActionUpAuctionLineRight>10%</CardActionUpAuctionLineRight>
        </CardActionLine>
        <CardActionUpAuctionButtonContainer>
          <Button appearance="primary" clickCallback={() => updateModal('Transaction submitted', <TransactionModal />)}>
            UP FOR AUCTION
          </Button>
        </CardActionUpAuctionButtonContainer>
      </CardActionUpAuctionContainer>
    </CardActionStyled>
  )
}
