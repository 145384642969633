import styled from 'styled-components/macro'

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: rgba(12, 12, 12, 0.82);
  border: 1px solid #61616B;
  backdrop-filter: blur(44px);
  width: 600px;
  height: 633px;
  margin-left: 20px;
  margin-right: 20px;
`

export const PhasesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  margin-top: 40px ;
`

export const PhaseTitle = styled.p`
  font-family: 'Circe';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 14px;
  color: #F8FAFC;
`

export const AmountSold = styled.p`
  font-family: 'Circe';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #F8FAFC;
  margin-top: 16px;
`

export const InfoContainerMobile = styled.div`
  background: rgba(12, 12, 12, 0.82);
  border-top: 1px solid #61616B;
  border-bottom: 1px solid #61616B;
  backdrop-filter: blur(44px);
`