import { Image, MaxScreenWidth } from 'styles'

import { EmailComponent } from '../EmailComponent/EmailComponent.view'
import {
  ColumnContainer,
  Copyright,
  CopyrightResponsive,
  FooterContainer,
  FooterLogo,
  FooterLogoBar,
  FooterLogoBarResponsive,
  FooterStyled,
  LeftPart,
  PolicyContainer,
  PolicyContainerResponsive,
  RightPart,
  RowContainer,
  RowContainerSocialLink,
  SocialLink,
  TextLink,
  TextLink2,
  Title,
} from './Footer.style'

interface FooterViewProps {
  version: number
}

export const FooterView = ({ version }: FooterViewProps) => {
  let policyComponent
  let emailComponent

  policyComponent = (
    <div>
      <PolicyContainer>
        <TextLink2 href="/privacy-policy">Privacy Policy</TextLink2>
        <TextLink2 href="/terms-of-service">Terms of Service</TextLink2>
        <TextLink2 href="/faq">FAQ</TextLink2>
        <TextLink2 href="/sitemap">Sitemap</TextLink2>
      </PolicyContainer>
      <PolicyContainerResponsive>
        <ColumnContainer>
          <TextLink href="/privacy-policy">Privacy Policy</TextLink>
          <TextLink href="/terms-of-service">Terms of Service</TextLink>
        </ColumnContainer>
        <ColumnContainer>
          <TextLink href="/faq">FAQ</TextLink>
          <TextLink href="/sitemap">Sitemap</TextLink>
        </ColumnContainer>
      </PolicyContainerResponsive>
    </div>
  )

  emailComponent = (
    <div>
      <Title>Stay up to-date on our latest news!</Title>
      <EmailComponent skew={true} buttonText="SIGN UP" emailListIds={[81]} />
    </div>
  )

  return (
    <FooterStyled>
      <MaxScreenWidth maxWidth="1400px">
        <FooterContainer>
          <RightPart>
            <FooterLogo alt="logo" src="/logo.svg" />
            <FooterLogoBar alt="logo-bar" src="/images/logo-bar.svg" />
            <RowContainer>
              <ColumnContainer>
                <Title>Documents</Title>
                <TextLink
                  href="https://mytvchain.io/wp-content/uploads/2022/01/Whitepaper-The-Fan2Earn-Experience-1.pdf"
                  target="_blank"
                >
                  Whitepaper
                </TextLink>
                <TextLink href="https://mytvchain.io/wp-content/uploads/2021/12/mytvchain_v3_1-1.pdf" target="_blank">
                  Research Paper
                </TextLink>
                <TextLink
                  href="https://mytvchain.io/wp-content/uploads/2022/01/MyTVchain2.0-One-Pager.pdf"
                  target="_blank"
                >
                  One-pager
                </TextLink>
              </ColumnContainer>
              <ColumnContainer>
                <Title>Company</Title>
                <TextLink href="https://mytvchain.io/" target="_blank">
                  mytvchain.io
                </TextLink>
                <TextLink href="https://mytvchain.medium.com/" target="_blank">
                  Blog
                </TextLink>
                <TextLink href="https://mytvchain.com/index.php" target="_blank">
                  mytvchain.com
                </TextLink>
              </ColumnContainer>
            </RowContainer>
            <Copyright>© 2022 MyTVchain</Copyright>
          </RightPart>
          <LeftPart>
            {version === 1 && emailComponent}
            <Title>Join the community!</Title>
            <RowContainerSocialLink>
              <SocialLink target="_blank" href="https://discord.gg/M2GAQQ64Fa">
                <Image style={{ height: '28px' }} alt="Discord" src={`/images/discord.svg`} />
              </SocialLink>
              <SocialLink target="_blank" href="https://twitter.com/MyTVchain">
                <Image style={{ height: '28px' }} alt="Twitter" src={`/images/twitter.svg`} />
              </SocialLink>
              <SocialLink target="_blank" href="https://www.facebook.com/MyTVchain/">
                <Image style={{ height: '28px' }} alt="Facebook" src={`/images/facebook.svg`} />
              </SocialLink>
              <SocialLink target="_blank" href="https://fr.linkedin.com/company/mytvchain">
                <Image style={{ height: '28px' }} alt="Linkedin" src={`/images/linkedin.svg`} />
              </SocialLink>
              <SocialLink target="_blank" href="https://www.instagram.com/mytvchain/">
                <Image style={{ height: '28px' }} alt="Instagram" src={`/images/instagram.svg`} />
              </SocialLink>
            </RowContainerSocialLink>
            <FooterLogoBarResponsive alt="logo-bar" src="/images/logo-bar.svg" />
            {version === 1 && policyComponent}
            <CopyrightResponsive>© 2022 MyTVchain</CopyrightResponsive>
          </LeftPart>
        </FooterContainer>
      </MaxScreenWidth>
    </FooterStyled>
  )
}
