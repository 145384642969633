import { Button } from 'app/App.components/Button/Button.view'
import { WalletState } from 'reducers/wallet'
import { TransactionModal } from '../../../TransactionModal/TransactionModal.controller'

import {
  CardActionStyled,
  CardActionContainer,
  CardActionFirstLine,
  CardActionBold,
  CardActionSubTitle,
  CardActionInputContainer,
  CardActionInputSkewLeft,
  CardActionInputSkewRight,
  CardActionInput,
  CardActionInputRight,
  CardActionBalanceContainer,
  CardActionBalanceTitle,
  CardActionBalance,
  CardActionBlueBar,
  CardActionLine,
  CardActionLineLeft,
  CardActionLineLeftGrid,
  CardActionLineLeftSubtext,
  CardActionLineRight,
  CardActionSeparator,
  CardActionButtonContainer,
} from '../../CardAction.style'

interface CardActionViewProps {
  version: number
  owner: string
  collection: string
  wallet: WalletState
  updateModal: (title: string, children: JSX.Element) => void
  item: any
}

export const MakeOfferView = ({ owner, collection, wallet, updateModal, item }: CardActionViewProps) => {
  return (
    <CardActionStyled>
      <CardActionContainer>
        <CardActionFirstLine>
          You make an offer for <CardActionBold>{owner}</CardActionBold> by{' '}
          <CardActionBold>{collection}</CardActionBold>
        </CardActionFirstLine>
        <CardActionSubTitle>Your offer</CardActionSubTitle>
        <CardActionInputContainer>
          <CardActionInput placeholder="Enter amount" />
          <CardActionInputSkewLeft />
          <CardActionInputRight>MYTV</CardActionInputRight>
          <CardActionInputSkewRight />
        </CardActionInputContainer>
        <CardActionBalanceContainer>
          <CardActionBalanceTitle>Your balance</CardActionBalanceTitle>
          <CardActionBalance>{wallet.currencyAmount} MYTV</CardActionBalance>
        </CardActionBalanceContainer>
        <CardActionBlueBar src="/images/blue-bar.svg" alt="" />
        <CardActionLine>
          <CardActionLineLeft>Expiration time</CardActionLineLeft>
          <CardActionLineRight>7 days</CardActionLineRight>
        </CardActionLine>
        <CardActionSeparator />
        <CardActionLine>
          <CardActionLineLeft>
            <CardActionLineLeftGrid>
              <div>Service Fees</div>
              <CardActionLineLeftSubtext>2%</CardActionLineLeftSubtext>
            </CardActionLineLeftGrid>
          </CardActionLineLeft>
          <CardActionLineRight>0 MYTV</CardActionLineRight>
        </CardActionLine>
        <CardActionLine>
          <CardActionLineLeft>
            <CardActionLineLeftGrid>
              <div>Royalties</div>
              <CardActionLineLeftSubtext>20%</CardActionLineLeftSubtext>
            </CardActionLineLeftGrid>
          </CardActionLineLeft>
          <CardActionLineRight>0 MYTV</CardActionLineRight>
        </CardActionLine>
        <CardActionButtonContainer>
          <Button appearance="primary" clickCallback={() => updateModal('Transaction submitted', <TransactionModal />)}>
            MAKE AN OFFER
          </Button>
        </CardActionButtonContainer>
      </CardActionContainer>
    </CardActionStyled>
  )
}
