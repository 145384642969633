/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from 'app/App.components/Button/Button.view'
import { MarketplaceCard } from 'app/App.components/Marketplace/MarketplaceCard/MarketplaceCard.controller'
import { NftEntity } from 'Entities'
import { useState } from 'react'
import { Box, Image } from 'styles'
import { formatAddress } from 'utils'

import { CardAction } from '../../app/App.components/CardAction/CardAction.controller'
import { ModalType } from '../../app/App.components/CardAction/CardAction.enum'
import {
  AttributeBlocksContainer,
  AttributeBlocksSuperiorContainer,
  BadgeContainer,
  ButtonContainer,
  ButtonsContainer,
  CardExtendedStyled,
  CardImage,
  CardOwner,
  CardText,
  CardTitle,
  CardTitleContainer,
  CollectionContainer,
  CollectionTitle,
  DetailsBlock,
  DetailsContainer,
  DetailsName,
  DetailsValue,
  FirstPartContainer,
  LeftPartContainer,
  LinkIcon,
  MoreAttributesContainer,
  OwnerContainer,
  Price,
  PriceAndButtonsContainer,
  PriceContainer,
  PriceInMyTv,
  ResponsiveBadgeContainer,
  RightPartContainer,
} from './CardExtended.style'
import { AttributeBlock } from './Components/AttributeBlock/AttributeBlock.view'
import { Badge } from './Components/Badge/Badge.view'
import { CardSubMenu } from './Components/CardSubMenu/CardSubMenu.view'

interface CardExtendedViewProps {
  version: number
  showModal: (title: string, children: JSX.Element) => void
  cardInfos: NftEntity | null
  imageLink: string
  sameCollection: NftEntity[]
}

export const CardExtendedView = ({
  version,
  showModal,
  cardInfos,
  imageLink,
  sameCollection,
}: CardExtendedViewProps) => {
  const [showing, setShowing] = useState(false)

  if (cardInfos) {
    return (
      <CardExtendedStyled>
        {version >= 1 && (
          <>
            <FirstPartContainer>
              <LeftPartContainer>
                <CardImage alt="Card image" src={imageLink}></CardImage>
              </LeftPartContainer>
              <RightPartContainer>
                <ResponsiveBadgeContainer>
                  <Badge type="epic"></Badge>
                </ResponsiveBadgeContainer>
                <CardTitleContainer>
                  <CardTitle>{cardInfos.name}</CardTitle>
                  <BadgeContainer>
                    <Badge type="legendary"></Badge>
                  </BadgeContainer>
                  <div></div>
                  <LinkIcon alt="Link icon" src="/images/link-icon.svg"></LinkIcon>
                </CardTitleContainer>
                <Image alt="blue line separator" src="/images/blue-line-separator.svg"></Image>
                <OwnerContainer>
                  <CardOwner>Owner : 0xb4ff…997e</CardOwner>
                </OwnerContainer>
                <CardText>{cardInfos.description}</CardText>
                <PriceAndButtonsContainer>
                  <PriceContainer>
                    <Price>Price</Price>
                    <Box display="flex" marginRight={14} marginBottom={24}>
                      <PriceInMyTv>100 MYTV</PriceInMyTv>
                      <Image marginLeft={14} src="/images/card-extended/mytv-coin.svg" />
                    </Box>
                  </PriceContainer>
                  <ButtonsContainer>
                    <Button
                      style={{ maxWidth: 194, maxHeight: 48 }}
                      appearance="primary_empty"
                      width={'100%'}
                      clickCallback={() =>
                        showModal(
                          'Make an offer',
                          <CardAction
                            owner="Michaël Guigou"
                            collection="FFHB"
                            type={ModalType.MAKE_OFFER}
                            item={cardInfos}
                          />,
                        )
                      }
                    >
                      MAKE AN OFFER
                    </Button>
                    <Button
                      style={{ maxWidth: 160, maxHeight: 48 }}
                      appearance="primary"
                      width={'100%'}
                      clickCallback={() =>
                        showModal(
                          'Place a bid',
                          <CardAction owner="Michaël Guigou" collection="FFHB" type={ModalType.BID} item={cardInfos} />,
                        )
                      }
                    >
                      PLACE A BID
                    </Button>
                  </ButtonsContainer>
                </PriceAndButtonsContainer>
                <CardSubMenu title="Details">
                  <DetailsContainer>
                    <DetailsBlock>
                      <DetailsName>Contract address</DetailsName>
                      <DetailsValue>{formatAddress(cardInfos.tokenSupport?.address!)}</DetailsValue>
                    </DetailsBlock>
                    <DetailsBlock>
                      <DetailsName>Token standard</DetailsName>
                      <DetailsValue>{cardInfos.tokenSupport?.protocol}</DetailsValue>
                    </DetailsBlock>
                    <DetailsBlock>
                      <DetailsName>Token ID</DetailsName>
                      <DetailsValue>{cardInfos.tokenId}</DetailsValue>
                    </DetailsBlock>
                    <DetailsBlock>
                      <DetailsName>Blockchain</DetailsName>
                      <DetailsValue>Ethereum</DetailsValue>
                    </DetailsBlock>
                  </DetailsContainer>
                </CardSubMenu>
                <CardSubMenu title="Attributes">
                  <AttributeBlocksSuperiorContainer>
                    <AttributeBlocksContainer>
                      {cardInfos?.attributes?.map((attribute: any, key: number) => {
                        return (
                          <AttributeBlock
                            title={attribute.trait_type}
                            subTitle={attribute.value}
                            key={key + cardInfos._id!}
                          ></AttributeBlock>
                        )
                      })}
                    </AttributeBlocksContainer>
                  </AttributeBlocksSuperiorContainer>
                  {/* </AttributeBlocksSuperiorContainer>
                  <AttributeBlocksSuperiorContainer>
                    <AttributeBlocksContainer>
                      <AttributeBlock title="Frame" subTitle="Silver" percentage={23}></AttributeBlock>
                      <AttributeBlock title="Background" subTitle="Blue" percentage={23}></AttributeBlock>
                      <AttributeBlock title="Badge" subTitle="European champion" percentage={7}></AttributeBlock>
                      <AttributeBlock title="Signature" subTitle="Gold" percentage={0.4}></AttributeBlock>
                      <AttributeBlock title="Values" subTitle="Fairplay" percentage={15}></AttributeBlock>
                    </AttributeBlocksContainer>
                  </AttributeBlocksSuperiorContainer>
                  <TwoAttributeBlocksContainerResponsive showing={showing}>
                    <AttributeBlock title="Frame" subTitle="Silver" percentage={23}></AttributeBlock>
                    <AttributeBlock title="Background" subTitle="Blue" percentage={23}></AttributeBlock>
                  </TwoAttributeBlocksContainerResponsive>
                  <MoreAttributeBlocksContainerResponsive showing={!showing}>
                    <AttributeBlock title="Frame" subTitle="Silver" percentage={23}></AttributeBlock>
                    <AttributeBlock title="Background" subTitle="Blue" percentage={23}></AttributeBlock>
                    <AttributeBlock title="Badge" subTitle="European champion" percentage={7}></AttributeBlock>
                    <AttributeBlock title="Signature" subTitle="Gold" percentage={0.4}></AttributeBlock>
                    <AttributeBlock title="Values" subTitle="Fairplay" percentage={15}></AttributeBlock>
                  </MoreAttributeBlocksContainerResponsive> */}
                  <MoreAttributesContainer
                    onClick={() => {
                      setShowing(!showing)
                    }}
                  >
                    {showing ? 'View less attributes ←' : 'View more attributes →'}
                  </MoreAttributesContainer>
                </CardSubMenu>
              </RightPartContainer>
            </FirstPartContainer>
            {sameCollection.length >= 1 && (
              <>
                <CollectionTitle>On the same collection</CollectionTitle>
                <CollectionContainer>
                  {sameCollection.map((item: any, key: any) => {
                    return <MarketplaceCard tokenInfos={item} grayed={item._id === cardInfos._id} key={item.id + key} />
                  })}
                </CollectionContainer>
                <ButtonContainer>
                  <Button appearance="primary_empty">VIEW COLLECTION</Button>
                </ButtonContainer>
              </>
            )}
          </>
        )}
      </CardExtendedStyled>
    )
  } else {
    return <CardExtendedStyled></CardExtendedStyled>
  }
}
