export default abstract class BaseService {

  public static async get(...args: any[]): Promise<any> {
    throw new Error('Method not implemented')
  }
  public async post(): Promise<any> {
    throw new Error('Method not implemented')
  }
  public async put(): Promise<any> {
    throw new Error('Method not implemented')
  }

  protected static async httpGet(url: URL): Promise<any> {
    return fetch(`${url.toString()}`, {method: 'GET'})
  }
  protected static async httpPost(url: URL, params: object): Promise<any> {
    return fetch(`${url.toString()}`, {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        'Content-Type': 'application/json',
      }})
  }
  protected static async httpPut(url: URL, params: object): Promise<any> {
    return fetch(`${url.toString()}`, {
      method: 'PUT',
      body: JSON.stringify(params),
      headers: {
        'Content-Type': 'application/json',
      }})
  }
}
