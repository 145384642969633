import { Button } from 'app/App.components/Button/Button.view'
import { ComingSoonImg } from 'app/App.components/ComingSoonImg/ComingSoonImg.view'
import { MarketplaceCard } from 'app/App.components/Marketplace/MarketplaceCard/MarketplaceCard.controller'
import { useHistory } from 'react-router'
import { formatAddress } from 'utils'
import { Loader } from 'app/App.components/Loader/Loader.view'
import { Image } from '../../styles'
import {
  ProfileAddress,
  ProfileBlueBar,
  ProfileCardsContainer,
  ProfileClipboardAddress,
  ProfileContainer,
  ProfileFilterSelect,
  ProfileFilterSelectContainer,
  ProfileFirstLine,
  ProfileNoNfts,
  ProfileNoNftsButtonContainer,
  ProfileNoNftsContainer,
  ProfilePicture,
  ProfileResultsNumber,
  ProfileSecondLine,
  ProfileSeparator,
  ProfileStyled,
} from './Profile.style'
import { ITokenSupportBalance } from 'Webservices/Erc20/Balances'

interface ProfileViewProps {
  version: number
  wallet: { address: string }
  personnalCollection: ITokenSupportBalance[]
  loading: boolean
  connectWallet: () => void
}

export const ProfileView = ({ version, wallet, personnalCollection, loading, connectWallet }: ProfileViewProps) => {
  const history = useHistory()

  return (
    <ProfileStyled>
      <ProfileContainer>
        <ProfileFirstLine>
          <ProfilePicture />
          <ProfileAddress>{formatAddress(wallet.address)}</ProfileAddress>
          <ProfileClipboardAddress
            onClick={() => {
              navigator.clipboard.writeText(wallet.address)
            }}
          >
            <svg>
              <use xlinkHref="/images/sprites.svg#clipboard-icon" />
            </svg>
          </ProfileClipboardAddress>
          <ProfileBlueBar>
            <img src="/images/blue-bar.svg" alt="" />
          </ProfileBlueBar>
        </ProfileFirstLine>
        <ProfileSecondLine>
          <ProfileResultsNumber>{personnalCollection.length} results</ProfileResultsNumber>
          <ProfileFilterSelectContainer>
            <ProfileFilterSelect disabled>
              <option>Sort by</option>
            </ProfileFilterSelect>
            <Image
              position={'absolute'}
              top={17}
              right={24}
              alt="search icon"
              src={`/images/marketplace/arrow-down.svg`}
            />
          </ProfileFilterSelectContainer>

          <ProfileFilterSelectContainer>
            <ProfileFilterSelect disabled>
              <option>Filter by</option>
            </ProfileFilterSelect>
            <Image
              position={'absolute'}
              top={17}
              right={24}
              alt="search icon"
              src={`/images/marketplace/arrow-down.svg`}
            />
          </ProfileFilterSelectContainer>
        </ProfileSecondLine>
      </ProfileContainer>
      <ProfileSeparator />
      {version >= 1 && (
        <ProfileContainer marginTop={40} marginBottom={130}>
          {wallet.address !== '' && (
            <>
              {!loading && (
                <>
                  {personnalCollection.length >= 1 && (
                    <ProfileCardsContainer>
                      {personnalCollection.map((item: any, key: number) => {
                        return <MarketplaceCard tokenInfos={item} forSale={true} key={item._id + key}></MarketplaceCard>
                      })}
                    </ProfileCardsContainer>
                  )}
                  {personnalCollection.length < 1 && (
                    <ProfileNoNftsContainer>
                      <ProfileNoNfts>You don't own any NFT... for now</ProfileNoNfts>
                      <ProfileNoNftsButtonContainer>
                        <Button
                          appearance="primary"
                          clickCallback={() => {
                            history.push('/marketplace')
                          }}
                        >
                          BUY ONE !
                        </Button>
                      </ProfileNoNftsButtonContainer>
                    </ProfileNoNftsContainer>
                  )}
                </>
              )}

              {loading && <Loader />}
            </>
          )}

          {wallet.address === '' && (
            <ProfileNoNftsContainer>
              <ProfileNoNfts>Please connect wallet to access the functionality</ProfileNoNfts>
              <ProfileNoNftsButtonContainer>
                <Button appearance="primary" clickCallback={() => connectWallet()}>
                  CONNECT WALLET
                </Button>
              </ProfileNoNftsButtonContainer>
            </ProfileNoNftsContainer>
          )}
        </ProfileContainer>
      )}
      {version === 0 && <ComingSoonImg margin={[50, 85]} />}
    </ProfileStyled>
  )
}
