// prettier-ignore
import * as React from 'react'

import { TableLineView } from './TableLine.view'

interface TableLineProps {
  id: number
}

export const TableLine = ({ id: number }: TableLineProps) => {
  let img = '/images/marketplace/cards/loeb.png'
  let price = 1
  let date = new Date()
  let name = 'Sebastien Loeb'
  let tokenId = '123456'
  let contractAddress = '0x06hjz...'
  let saleType = 'Auction'
  let txHash = '0xRa...f53'

  return (
    <TableLineView
      img={img}
      price={price}
      date={date}
      name={name}
      tokenId={tokenId}
      contractAddress={contractAddress}
      saleType={saleType}
      txHash={txHash}
    />
  )
}
