import * as React from 'react'
import { Loader } from '../Loader/Loader.view'

import { TimerStyled, TimerNumber, TimerText, TimerEnded } from './Timer.style'

type TimerViewProps = {
  seconds: number
  minutes: number
  hours: number
  active: boolean
}

export const TimerView = ({ seconds, minutes, hours, active }: TimerViewProps) => {
  return (
    <>
      {hours === 0 && minutes === 0 && seconds === 0 && active && <Loader width={'50px'} />}
      {active && hours !== 0 && minutes !== 0 && seconds !== 0 && (
        <TimerStyled>
          <TimerNumber>{hours < 10 ? `0${hours}` : hours}</TimerNumber>
          <TimerNumber>:</TimerNumber>
          <TimerNumber>{minutes < 10 ? `0${minutes}` : minutes}</TimerNumber>
          <TimerNumber>:</TimerNumber>
          <TimerNumber>{seconds < 10 ? `0${seconds}` : seconds}</TimerNumber>
          <TimerText>hours</TimerText>
          <TimerText></TimerText>
          <TimerText>minutes</TimerText>
          <TimerText></TimerText>
          <TimerText>seconds</TimerText>
        </TimerStyled>
      )}
      {!active && <TimerEnded>ENDED</TimerEnded>}
    </>
  )
}

TimerView.propTypes = {}

TimerView.defaultProps = {}
