import Torus from '@toruslabs/torus-embed'
import WalletConnectProvider from '@walletconnect/web3-provider'
import Web3 from 'web3'
import Web3Modal from 'web3modal'

export const SET_ADDRESS = 'SET_ADDRESS'
export const SET_CURRENCYAMOUNT = 'SET_CURRENCYAMOUNT'
export const SET_WEB3 = 'SET_WEB3'
export const LOGOUT = 'LOGOUT'

export const setAddress = (address: string) => (dispatch: any) => {
  dispatch({
    type: SET_ADDRESS,
    address,
  })
}

export const setCurrencyAmount = (currencyAmount: number) => (dispatch: any) => {
  dispatch({
    type: SET_CURRENCYAMOUNT,
    currencyAmount,
  })
}

export const setWeb3 = (web3: Web3) => (dispatch: any) => {
  dispatch({
    type: SET_WEB3,
    web3,
  })
}

export const logout = () => async (dispatch: any) => {
  let web3Modal = new Web3Modal()
  if (web3Modal.cachedProvider === 'injected') {
    await web3Modal.clearCachedProvider()
  }
  dispatch({
    type: LOGOUT,
  })
}

export const connectWallet = () => async (dispatch: any) => {
  try {
    let web3Modal = new Web3Modal({
      network: 'mainnet', // optional
      cacheProvider: true,
      providerOptions: {
        walletconnect: {
          package: WalletConnectProvider, // required
          options: {
            infuraId: 'INFURA_ID', // required
          },
        },
        torus: {
          package: Torus,
        },
        binancechainwallet: {
          package: true,
        },
      }, // required
    })
    await web3Modal.clearCachedProvider()
    const provider = await web3Modal.connect()
    const web3 = new Web3(provider)
    web3.eth.getAccounts((error, accounts) => {
      dispatch({
        type: SET_WEB3,
        address: accounts[0],
        currencyAmount: 0,
        web3,
        provider,
      })
    })

    if ((window as any).ethereum !== undefined) {
      if (process.env.REACT_APP_DEFAULT_CHAINID !== '0x4') {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            { rpcUrls: [process.env.REACT_APP_DEFAULT_NETWORK], chainId: process.env.REACT_APP_DEFAULT_CHAINID },
          ], // chainId must be in hexadecimal numbers
        })
      }
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: process.env.REACT_APP_DEFAULT_CHAINID }], // chainId must be in hexadecimal numbers
      })
    }
  } catch (e) {
    if (e !== 'Modal closed by user') {
      alert("You don't have this wallet installed")
    }
  }
}
